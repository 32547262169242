import React from 'react';
import { Table, } from 'react-bootstrap';
import { GetAdminResponse, } from './apiCalls';
import { useSelector, } from 'react-redux';
import { selectUserId, } from './profileSlice';

const AdminRow = ({ admin, isCurrentUser }: { admin: GetAdminResponse, isCurrentUser: boolean }) => {
  return (
    <tr>
      <td>{isCurrentUser ? `${admin.id} (you)` : admin.id}</td>
      <td>{admin.email}</td>

      <td>
        {/* The follow evaluates to ?  ✅ : ❌ */}
        {admin.admin ? '\u{2705}' : '\u{274C}'}
      </td>
    </tr>
  );
};

const AdminList = ({ admins }: { admins: GetAdminResponse[] }) => {
  const currentUserId = useSelector(selectUserId);

  return (<Table striped>
    <thead>
    <tr>
      <td>User ID</td>
      <td>User Email</td>
      <td>Admin Status</td>
    </tr>
    </thead>
    <tbody>
    {
      admins.map(a => <AdminRow admin={a} key={a.id} isCurrentUser={a.id === currentUserId} />)
    }
    </tbody>
  </Table>);
};

export default AdminList;
