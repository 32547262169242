import { Table, } from 'react-bootstrap';
import UploadImage from './UploadImage';
import React, { useContext, } from 'react';
import { ImagesDataContext, } from './UploadForm';
import { useSelector, } from 'react-redux';
import { selectFilesList, UploadStatus, } from './uploadSlice';

const UploadTable = () => {
  const context = useContext(ImagesDataContext);
  const reduxFiles = useSelector(selectFilesList);
  if (context.uploadData.length > 0) {
    return (
      <Table striped bordered hover size="lg" className="p-3">
        <thead>
        <tr>
          <th>Filename</th>
          <th>Uploaded</th>
          <th
            data-bs-toggle={'tooltip'}
            data-bs-placement={'right'}
            title={'Choose whether the image is portrait oriented or landscape oriented. If not selected as portrait, it will be assumed landscape'}>
            Orientation
          </th>
          <th>Species</th>
          <th>Exhibitor</th>
        </tr>
        </thead>
        <tbody>
        {
          context.uploadData.map(f => {
            const stateFile = reduxFiles.find(rf => rf.fileName === f.image.name);
            return <UploadImage key={f.image.name} file={f.image} uploadDetails={stateFile?.uploadStatus || UploadStatus.Empty} />;
          })
        }
        </tbody>
      </Table>);
  } else {
    return <></>;
  }
};

export default UploadTable;
