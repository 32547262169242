import Login from './Login';
import Register from './Register';
import React from 'react';
import { useAppDispatch, } from '../../app/hooks';
import { selectJwt, signOut, } from './authSlice';
import { useSelector, } from 'react-redux';
import { Button, } from 'react-bootstrap';

const Auth = () => {
  const jwt = useSelector(selectJwt);
  const dispatch = useAppDispatch();
  let renderItem = <div className="p-3">
    <Login />
    <hr />
    <Register />
  </div>;
  if (jwt) {
    renderItem = <div className="p-3">
      <h3>You are logged in.</h3>
      <p>If you just signed up, you must verify your email before continuing.
        You also must be authorized by an admin user before making any changes.</p>
      <Button
        className="mb-3"
        variant="primary"
        onClick={() => dispatch(signOut())}
      >Sign Out</Button>
    </div>;
  }
  return <>
    {renderItem}
  </>;
};

export default Auth;
