import { createSlice, PayloadAction, } from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';

export enum ActiveItem {
  Home,
  Auth,
  Profile,
  Species,
  Exhibitor,
  Images,
  Days,
  Upload,
}

export interface NavSlice {
  activeItem: ActiveItem;
}

const initialState: NavSlice = {
  activeItem: ActiveItem.Home,
};
export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    changeActiveItem: (state, action: PayloadAction<ActiveItem>) => {
      state.activeItem = action.payload;
    }
  },
});

export const { changeActiveItem } = navSlice.actions;
export const selectActiveItem = (state: RootState) => state.nav.activeItem;
export default navSlice.reducer;
