import { Button, } from 'react-bootstrap';
import { useAppDispatch, } from '../app/hooks';
import { ActiveItem, changeActiveItem, } from '../features/nav/navSlice';
import React from 'react';

const NotLoggedIn = () => {
  const dispatch = useAppDispatch();
  return (
    <div className={'p-3'}>
      <h2>You are not signed in!</h2>
      <Button onClick={() => {
        dispatch(changeActiveItem(ActiveItem.Auth));
      }}>Go to Sign in</Button>
    </div>
  );
};

export default NotLoggedIn;
