import React, { useContext, useState, } from 'react';
import { ImagesDataContext, } from './UploadForm';
import { useSelector, } from 'react-redux';
import { speciesListSelector, } from '../species/speciesSlice';
import { exhibitorsListSelector, } from '../exhibitor/exhibitorSlice';
import SpeciesSelector from '../../components/SpeciesSelector';
import ExhibitorSelector from '../../components/ExhibitorSelector';
import OrientationSelector from '../../components/OrientationSelector';
import { UploadStatus, } from './uploadSlice';
import { PENDING_SPINNER, } from '../../common/uiText';

export interface UploadImageProps {
  file: File,
  uploadDetails: UploadStatus
}

const UploadDetails = ({ uploadStatus }: {uploadStatus: UploadStatus}) => {
  switch (uploadStatus) {
    case UploadStatus.Success:
      return <>{'Success!'}</>;
    case UploadStatus.Error:
      return <>{'Error!'}</>;
    case UploadStatus.Empty:
      return <></>;
    case UploadStatus.Pending:
      return PENDING_SPINNER;
  }
};

const UploadImage = ({
  file,
  uploadDetails
}: UploadImageProps) => {
  const context = useContext(ImagesDataContext);
  const ctxFile = context.uploadData.find(i => i.image.name === file.name)!;
  const species = useSelector(speciesListSelector);
  const exhibitors = useSelector(exhibitorsListSelector);
  const [isPortrait, setIsPortrait] = useState(ctxFile.portrait);

  return (<tr>
      <td>{file.name}</td>
      <td><UploadDetails uploadStatus={uploadDetails} /></td>
      <td>
        <OrientationSelector
          isPortrait={isPortrait}
          onChange={() => {
            const newIsPortrait = !isPortrait;
            setIsPortrait(newIsPortrait);
            ctxFile.portrait = newIsPortrait;
          }} />
      </td>
      <td>
        <SpeciesSelector
          onChange={(e) => {
            ctxFile.species = Number(e.target.value);
          }}
          species={species}
        />
      </td>
      <td>
        <ExhibitorSelector
          onChange={(e) => {
            ctxFile.exhibitor = Number(e.target.value);
          }}
          exhibitors={exhibitors}
        />
      </td>
    </tr>
  );
};

export default UploadImage;
