import * as EmailValidator from 'email-validator';
import {
  Button,
  Form,
  Modal,
} from 'react-bootstrap';
import { SubmitHandler, useForm, } from 'react-hook-form';
import React, { useState, } from 'react';
import { useAppDispatch, } from '../../app/hooks';
import { selectModalText, signupThunk, } from './authSlice';
import { createToast, } from '../toasts/toastSlice';
import { useSelector, } from 'react-redux';

const UserName = 'Username';
const UserPassword = 'Password';
const Submit = 'Submit';
const ACCOUNT_CREATION_ERROR = 'Account Creation Error';
const PASSWORD_INVALID = 'Password is invalid: Must be at least 8 characters';
const PASSWORD_NONMATCHING = 'Passwords do not match';
const EMAIL_INVALID = 'Email is invalid';
const MIN_PASSWORD_LEN = 8;

type Inputs = {
  email: string,
  password: string
  confirmPassword: string
}

const passwordsMatch = (inputs: Inputs): boolean => {
  return inputs.confirmPassword === inputs.password;
};
const passwordIsValid = (inputs: Inputs): boolean => {
  return inputs.password.length >= MIN_PASSWORD_LEN;
};
const emailIsValid = (inputs: Inputs): boolean => {
  return EmailValidator.validate(inputs.email);
};

const RegisterModal = ({ onCancel, show }: {onCancel: ()=> void, show: boolean,}) => {
  const bodyText = useSelector(selectModalText);
  return <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={show}
    onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Sign up
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        {bodyText}
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onCancel}>Okay</Button>
    </Modal.Footer>
  </Modal>;
};

const Register = () => {
  const {
    register,
    handleSubmit,
  } = useForm<Inputs>();
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<Inputs> = (inputs) => {
    if (!emailIsValid(inputs)) {
      dispatch(createToast({
        title: ACCOUNT_CREATION_ERROR,
        details: EMAIL_INVALID,
        type: 'Secondary',
      }));
    } else if (!passwordsMatch(inputs)) {
      dispatch(createToast({
        title: ACCOUNT_CREATION_ERROR,
        details: PASSWORD_NONMATCHING,
        type: 'Secondary',
      }));
    } else if (!passwordIsValid(inputs)) {
      dispatch(createToast({
        title: ACCOUNT_CREATION_ERROR,
        details: PASSWORD_INVALID,
        type: 'Secondary',
      }));
    } else {
      // Everything is valid
      setModalShow(true);
      dispatch(signupThunk(inputs));
    }
  };

  return <>
    <h2 className={'p-2'}>Register</h2>
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="p-3"
    >
      <Form.Group className="mb-3" controlId={UserName}>
        <Form.Label>{UserName}</Form.Label>
        <Form.Control
          type="text"
          placeholder={`Enter ${UserName}`}
          {...register('email', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={UserPassword}>
        <Form.Label>{UserPassword}</Form.Label>
        <Form.Control
          type="password"
          placeholder={`Enter ${UserPassword}`}
          {...register('password', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={UserPassword}>
        <Form.Label>{'Confirm Password'}</Form.Label>
        <Form.Control
          type="password"
          placeholder={`Confirm ${UserPassword}`}
          {...register('confirmPassword', { required: true })}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        {Submit}
      </Button>
    </Form>
    <RegisterModal onCancel={() => setModalShow(false)} show={modalShow} />
    </>
  ;
};

export default Register;
