import React, {
  ChangeEvent,
  createContext,
  useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { Button, } from 'react-bootstrap';
import FileList from 'react-dom';
import HTMLInputElement from 'react-dom';
import HTMLFormElement from 'react-dom';
import UploadTable from './UploadTable';
import {
  ButterflyUpload,
  changeFiles,
  uploadThunk,
} from './uploadSlice';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../auth/authSlice';
import { useAppDispatch, } from '../../app/hooks';
import { getExhibitorThunk, } from '../exhibitor/exhibitorSlice';
import { getSpeciesThunk, } from '../species/speciesSlice';

const FILE = 'Upload Image';
const SUBMIT = 'Submit';

export interface ImagesContext {
  uploadData: ButterflyUpload[];
}

export const ImagesDataContext = createContext<ImagesContext>({ uploadData: [] });
const UploadForm = () => {
  const [filesList, setFilesList] = useState<ButterflyUpload[]>();
  const imagesData = filesList ? filesList as ButterflyUpload[] : [];
  const jwt = useSelector(selectJwt);
  const dispatch = useAppDispatch();
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files as FileList) : [];

    dispatch(changeFiles(files.map(f => f.name)));

    setFilesList(files.map(f => {
      return {
        image: f,
        portrait: false,
      };
    }));
  };

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    imagesData.forEach(upload => {
      dispatch(uploadThunk({
        jwt,
        upload
      }));
    });
  };
  return <>
    <Form
      className="m-3"
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3" controlId={FILE} onChange={handleFileChange} onClick={() => {
        dispatch(getExhibitorThunk({
          qp: {},
          jwt
        }));
        dispatch(getSpeciesThunk({ jwt }));
      }}>
        <Form.Label>{FILE}</Form.Label>
        <Form.Control type="file" multiple accept="image/*" />
      </Form.Group>

      <Button variant="primary" type="submit">
        {SUBMIT}
      </Button>
    </Form>
    <ImagesDataContext.Provider value={{ uploadData: imagesData }}>
      <UploadTable />
    </ImagesDataContext.Provider>
  </>;
};

export default UploadForm;
