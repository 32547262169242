import React from 'react';
import { Button, } from 'react-bootstrap';
import { useSelector, } from 'react-redux';
import {
  deleteAccountThunk,
  selectAdmins,
  selectUserId,
} from './profileSlice';
import { useAppDispatch, } from '../../app/hooks';
import { selectJwt, } from '../auth/authSlice';
import AuthorizeUserForm from './AuthorizeUserForm';
import AdminList from './AdminList';

const Profile = () => {
  const dispatch = useAppDispatch();
  const admins = useSelector(selectAdmins);
  const jwt = useSelector(selectJwt);
  const uid = useSelector(selectUserId);
  return (
    <div className="p-3">
      <h2>Your UserId is &lsquo;{uid}&rsquo;</h2>
      <Button
        variant="danger"
        onClick={() => dispatch(deleteAccountThunk({
          jwt,
          uid
        }))}
      >Delete Account</Button>
      <br />
      <br />
      {/* Probably not the best practice, but i am using the admins len property to check if they permissions */}
      {admins.length > 0 && <AuthorizeUserForm />}
      <br />
      <br />
      {/* Probably not the best practice, but i am using the admins len property to check if they permissions */}
      {admins.length > 0 && <AdminList admins={admins} />}
    </div>
  );
};

export default Profile;
