import { ButterflyUpload, UploadResponse, } from './uploadSlice';
import { API_BASE_PATH, } from '../../webc/webClient';

const FILE_UPLOAD_PATH = 'image';

export const uploadPhoto = async (f: ButterflyUpload, jwt: string) => {
  const formData = new FormData();
  Object.entries(f).forEach(kv => formData.append(kv[0], kv[1]));

  const url = `${API_BASE_PATH}/${FILE_UPLOAD_PATH}?jwt=${jwt}`;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    body: formData
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.body.toString());
  }
  return json as UploadResponse;
};
