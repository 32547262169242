import React from 'react';
import { exhibitorsStatusSelector, ExhibitorStatus, } from './exhibitorSlice';
import { useSelector, } from 'react-redux';
import ExhibitorsTable from './ExhibitorsTable';
import { PENDING_SPINNER, REJECTED_MSG, } from '../../common/uiText';
import { Container, Row, } from 'react-bootstrap';

const Exhibitors = () => {
  const status = useSelector(exhibitorsStatusSelector);

  switch (status) {
    case ExhibitorStatus.Fulfilled:
      return <ExhibitorsTable />;
    case ExhibitorStatus.Pending:
    case ExhibitorStatus.Uninitialized:
      return <Container><Row className="justify-content-md-center">{PENDING_SPINNER}</Row></Container>;
    case ExhibitorStatus.Unauthorized:
    case ExhibitorStatus.Rejected:
      return <h4 className={'p-3'}>{REJECTED_MSG}</h4>;
  }
};

export default Exhibitors;
