import { createSlice, PayloadAction, } from '@reduxjs/toolkit';
import {
  LoginDetailsError,
  loginThunk,
  signupThunk,
} from '../auth/authSlice';
import { v4 as uuidv4, } from 'uuid';
import { RootState, } from '../../app/store';

export type ToastType = 'Primary' | 'Danger' | 'Success' | 'Secondary'

export interface ToastCreationItems {
  title: string,
  details: string,
  type: ToastType,
}

export interface Toast extends ToastCreationItems{
  id: string,
  timeGenerated: number, // ms since epoch
}

export interface ToastSlice {
  toasts: Toast[],
}

const initialState: ToastSlice = { toasts: [] };

const toastSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    createToast: (state, action: PayloadAction<ToastCreationItems>) => {
      state.toasts.push({
        id: uuidv4(),
        timeGenerated: Date.now(),
        ...action.payload,
      });
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(t => t.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.rejected, (state, action) => {
        const payload = action.payload as LoginDetailsError;

        const uuid = uuidv4();
        const newToast: Toast = {
          details: payload.detail,
          id: uuid,
          timeGenerated: Date.now(),
          title: 'Login error',
          type: 'Danger'
        };

        state.toasts.push(newToast);
      })
      .addCase(signupThunk.rejected, (state, action) => {
        const payload = action.payload as LoginDetailsError;

        const uuid = uuidv4();
        const newToast: Toast = {
          details: payload.detail,
          id: uuid,
          timeGenerated: Date.now(),
          title: 'Signup error',
          type: 'Danger'
        };

        state.toasts.push(newToast);
      })
    ;
  }
});
export const { createToast, removeToast } = toastSlice.actions;
export const selectToasts = (state: RootState): Toast[] => state.toasts.toasts;
export default toastSlice.reducer;
