import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  deleteImageCall,
  editImageCall,
  EditImageParams,
  getImageCall,
  GetImageCallParams,
  SingleImageResponse,
} from './apiCalls';
import { RootState, } from '../../app/store';
import { stringSearch, } from '../../util/stringSearch';

export interface SingleImage extends SingleImageResponse {
  isEditing: boolean,
  editingData: EditImageParams,
  isPending: boolean,
}

export interface FilterParams {
  id: number,
  filename: string,
  scientificName: string,
  commonName: string,
  exhibitorName: string,
}

export interface ImageSlice {
  images: SingleImage[],
  filterImages: SingleImage[],
  filterIsEnabled: boolean,
  filterParams: FilterParams,
  pendingRequest: boolean,
}

const initialState: ImageSlice = {
  images: [],
  filterImages: [],
  filterIsEnabled: false,
  filterParams: {
    id: -1,
    filename: '',
    scientificName: '',
    commonName: '',
    exhibitorName: '',
  },
  pendingRequest: false,
};

export const getImagesThunk = createAsyncThunk(
  'image/getImage',
  async ({
    qp,
    jwt
  }: { jwt: string, qp: GetImageCallParams }, { rejectWithValue }) => {
    try {
      return await getImageCall(qp, jwt);
    } catch (err) {
      return rejectWithValue(qp);
    }
  }
);

export interface PutDeleteQueryParams {
  jwt: string,
  id: number
}

export interface DeletePutImageResponse {
  id: number,
  exhibitor: number,
  species: number,
  parent: number,
  filename: string
}

export const deleteImageThunk = createAsyncThunk(
  'image/removeImage',
  async ({
    jwt,
    id
  }: PutDeleteQueryParams, { rejectWithValue }) => {
    try {
      return await deleteImageCall(id, jwt);
    } catch (err) {
      return rejectWithValue(id);
    }
  }
);

export const saveEditingImageThunk = createAsyncThunk(
  'image/saveEditingImage',
  async ({
    editingImageParams,
    editingImageId,
    jwt
  }: { editingImageParams: EditImageParams, editingImageId: number, jwt: string }, { rejectWithValue }) => {
    try {
      return await editImageCall(editingImageParams, jwt, editingImageId);
    } catch (e) {
      return rejectWithValue(editingImageId);
    }
  }
);

const filterAllParams = (image: Draft<SingleImageResponse>, actionFilter: FilterParams): boolean => {
  let idsEqual: boolean;
  if (actionFilter.id > -1) {
    idsEqual = image?.id === actionFilter.id;
  } else {
    idsEqual = true;
  }
  const sciNamesEqual = stringSearch(image.species?.scientific_name, actionFilter?.scientificName);
  const comNamesEqual = stringSearch(image.species?.common_name, actionFilter?.commonName);
  const exhibitorNamesEqual = stringSearch(image.exhibitor?.name, actionFilter?.exhibitorName);
  return idsEqual && sciNamesEqual && comNamesEqual && exhibitorNamesEqual;
};
export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    enableFilter: (state) => {
      state.filterIsEnabled = true;
      state.filterImages = state.images.filter(image => filterAllParams(image, state.filterParams));
    },
    disableFilter: (state) => {
      state.filterIsEnabled = false;
      state.filterImages = state.images;
    },
    updateFilter: (state, action: PayloadAction<FilterParams>) => {
      state.filterParams = action.payload;
      if (state.filterIsEnabled) {
        state.filterImages = state.images.filter(image => filterAllParams(image, state.filterParams));
      }
    },
    removeFilter: (state) => {
      state.filterParams = {
        id: -1,
        filename: '',
        scientificName: '',
        commonName: '',
        exhibitorName: '',
      };
      state.filterImages = state.images;
    },
    editImage: (state, action: PayloadAction<number>) => {
      const image = state.images.find(i => i.id === action.payload);
      const fimage = state.filterImages.find(i => i.id === action.payload);
      if (image) {
        image.isEditing = true;
      }
      if (fimage) {
        fimage.isEditing = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImagesThunk.fulfilled, (state, action) => {
        state.pendingRequest = false;
        state.images = action.payload.map(img => {
          return {
            isEditing: false,
            isPending: false,
            editingData: { portrait: false },
            ...img
          };
        });
        state.filterImages = state.images;
      })
      .addCase(getImagesThunk.pending, (state) => {
        state.pendingRequest = true;
      })
      .addCase(getImagesThunk.rejected, (state) => {
        state.pendingRequest = false;
      })
      .addCase(deleteImageThunk.fulfilled, (state, action) => {
        state.images = state.images.filter(img => !action.payload.some(delImg => delImg.id === img.id));
        if (state.filterIsEnabled) {
          state.filterImages = state.images.filter(image => filterAllParams(image, state.filterParams));
        } else {
          state.filterImages = state.images;
        }
      })
      .addCase(deleteImageThunk.pending, (state, { meta }) => {
        const image = state.images.find(i => i.id === meta.arg.id);
        const fimage = state.filterImages.find(i => i.id === meta.arg.id);
        if (image) {
          image.isPending = true;
        }
        if (fimage) {
          fimage.isPending = true;
        }
      })
      .addCase(saveEditingImageThunk.fulfilled, (state, action) => {
        const imageIndex = state.images.findIndex(i => i.id === action.payload.id);
        const filterImageIndex = state.filterImages.findIndex(i => i.id === action.payload.id);

        // return if either index is not found
        if (imageIndex < 0 || filterImageIndex < 0) {
          return;
          // todo, this could probably be handled more gracefully
        }
        state.images[imageIndex] = {
          isEditing: false,
          isPending: false,
          editingData: { portrait: false },
          ...action.payload,
        };
        state.filterImages[filterImageIndex] = {
          isEditing: false,
          isPending: false,
          editingData: { portrait: false },
          ...action.payload
        };
      })
      .addCase(saveEditingImageThunk.pending, (state, { meta }) => {
        const image = state.images.find(i => i.id === meta.arg.editingImageId);
        const fimage = state.filterImages.find(i => i.id === meta.arg.editingImageId);
        if (image) {
          image.isPending = true;
        }
        if (fimage) {
          fimage.isPending = true;
        }
      })
    ;
  }
});

export const selectFullImagesList = (state: RootState) => state.images.images;
export const selectImagesViewList = (state: RootState) => state.images.filterImages;
export const selectFilterIsEnabled = (state: RootState) => state.images.filterIsEnabled;
export const selectIsImageRequestPending = (state: RootState) => state.images.pendingRequest;

export const {
  enableFilter,
  disableFilter,
  updateFilter,
  removeFilter,
  editImage,
} = imageSlice.actions;
export default imageSlice.reducer;
