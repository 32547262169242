import React from 'react';
import { Toast, ToastContainer, } from 'react-bootstrap';
import { useSelector, } from 'react-redux';
import { removeToast, selectToasts, } from './toastSlice';
import { useAppDispatch, } from '../../app/hooks';

const Toasts = () => {
  const toasts = useSelector(selectToasts);
  const dispatch = useAppDispatch();
  return (
    <ToastContainer
      className="p-3 toast-container position-fixed"
      position="bottom-end"
    >
      {
        toasts.map(t => {
          return (<Toast
            className={`bg-${t.type.toLowerCase()}`}
            key={t.id}
            onClose={() => {
              dispatch(removeToast(t.id));
            }}
          >
            <Toast.Header>
              {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
              <strong className="me-auto">{t.title}</strong>
              {/* <small className="text-muted">just now</small> */}
            </Toast.Header>
            <Toast.Body>
              {t.details || 'Unknown Error'}
            </Toast.Body>
          </Toast>);
        })
      }
    </ToastContainer>
  );
};

export default Toasts;
