import {
  webDelete,
  webGet,
  webPut,
} from '../../webc/webClient';
import { DateItem, } from './dateSlice';

const DAY_ROUTE = 'day';

export interface GetDateCallParams {
  jwt: string,
  date?: string,
}

export const getDateCall = async (qp: GetDateCallParams): Promise<DateItem[]> => {
  const response = await webGet(DAY_ROUTE, qp);
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  }
  return json as DateItem[];
};

export const putDateCall = async (jwt: string, body: { date: string, portrait_img: number, landscape_img: number }): Promise<DateItem> => {
  let path = DAY_ROUTE;
  path += '?';
  path += new URLSearchParams({ jwt });
  const response = await webPut(path, body);
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  }
  return json as DateItem;
};

export const deleteDateCall = async (jwt: string, date: string): Promise<DateItem> => {
  const response = await webDelete(DAY_ROUTE, {
    jwt,
    date
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  }
  return json as DateItem;
};
