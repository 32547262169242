export interface Images {
  originalPath: string,
  horizontalPath: string,
  verticalPath: string,
  squarePath: string,
}

export interface Butterfly {
  id: number,
  commonName: string,
  scientificName: string,
  images: Images,
}

export interface RejectedResponse {
  detail: [
    {
      loc: [string, number],
      msg: string,
      type: string
    }
  ];
}

export class BadRequestError extends Error {
  code: number;
  detail: string;

  constructor(code: number, detail: string, message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.detail = detail;
    this.code = code;
  }
}
