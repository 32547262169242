import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';
import { uploadPhoto, } from './apiCalls';
export interface ButterflyUpload {
  image: File,
  species?: number, // id
  exhibitor?: number, // id
  portrait: boolean,
}

interface ButterflyUploadThunkParams {
  upload: ButterflyUpload,
  jwt: string
}

export interface UploadResponse {
  id: number,
  filename: string,
  species?: number, // id
  exhibitor?: number, // id
}

export enum UploadStatus {
  Error = 'Error',
  Success = 'Success',
  Pending = 'Pending',
  Empty = 'Empty',
}

export interface UploadSlice {
  files: UploadFile[];
}

interface UploadFile {
  fileName: string,
  uploadStatus: UploadStatus,
}

const initialState: UploadSlice = {
  files: []
};

export const uploadThunk = createAsyncThunk(
  'upload/addImage',
  async ({
    upload,
    jwt
  }: ButterflyUploadThunkParams, { rejectWithValue }) => {
    try {
      return await uploadPhoto(upload, jwt);
    } catch (err) {
      return rejectWithValue(upload.image.name);
    }
  }
);

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    changeFiles: (state, action: PayloadAction<string[]>) => {
      state.files = action.payload.map(f => {
        return {
          fileName: f,
          uploadStatus: UploadStatus.Empty
        };
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadThunk.fulfilled, (state, action: PayloadAction<UploadResponse>) => {
        const file = state.files.find(f => f.fileName === action.payload.filename);
        if (file) {
          file.uploadStatus = UploadStatus.Success;
        }
      })
      .addCase(uploadThunk.rejected, (state, action) => {
        const file = state.files.find(f => f.fileName === action.payload);
        if (file) {
          file.uploadStatus = UploadStatus.Error;
        }
      })
      .addCase(uploadThunk.pending, (state, { meta }) => {
        const file = state.files.find(f => f.fileName === meta.arg.upload.image.name);
        if (file) {
          file.uploadStatus = UploadStatus.Pending;
        }
      })
    ;
  }
});
export const { changeFiles } = uploadSlice.actions;

export const selectFilesList = (state: RootState) => state.upload.files;

export default uploadSlice.reducer;
