import { ActiveItem, } from '../features/nav/navSlice';
import Auth from '../features/auth/Auth';
import LoggedInChecker from './LoggedInChecker';
import Profile from '../features/profile/Profile';
import ImageTable from '../features/image/ImageTable';
import UploadForm from '../features/upload/UploadForm';
import React from 'react';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../features/auth/authSlice';
import DateTab from '../features/date/DateTab';
import Home from '../features/home/Home';
import Exhibitors from '../features/exhibitor/Exhibitors';
import Species from '../features/species/Species';

const CurrentlyActiveItem = ({ state }: { state: ActiveItem }) => {
  const jwt = useSelector(selectJwt);
  switch (state) {
    case ActiveItem.Home:
      return <Home jwt={jwt} />;
    case ActiveItem.Auth:
      return <Auth />;
    case ActiveItem.Profile:
      return <LoggedInChecker jwt={jwt} Component={Profile} />;
    case ActiveItem.Species:
      return <LoggedInChecker jwt={jwt} Component={Species} />;
    case ActiveItem.Exhibitor:
      return <LoggedInChecker jwt={jwt} Component={Exhibitors} />;
    case ActiveItem.Images:
      return <LoggedInChecker jwt={jwt} Component={ImageTable} />;
    case ActiveItem.Days:
      return <LoggedInChecker jwt={jwt} Component={DateTab} />;
    case ActiveItem.Upload:
      return <LoggedInChecker jwt={jwt} Component={UploadForm} />;
  }
};

export default CurrentlyActiveItem;
