import {
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import navReducer from '../features/nav/navSlice';
import speciesReducer from '../features/species/speciesSlice';
import uploadReducer from '../features/upload/uploadSlice';
import exhibitorReducer from '../features/exhibitor/exhibitorSlice';
import imageReducer from '../features/image/imageSlice';
import profileReducer from '../features/profile/profileSlice';
import dateReducer from '../features/date/dateSlice';
import homeReducer from '../features/home/homeSlice';
import toastsReducer from '../features/toasts/toastSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    nav: navReducer,
    species: speciesReducer,
    upload: uploadReducer,
    exhibitors: exhibitorReducer,
    images: imageReducer,
    profile: profileReducer,
    date: dateReducer,
    home: homeReducer,
    toasts: toastsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
