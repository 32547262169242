import { Button, FormControl, } from 'react-bootstrap';
import {
  editExhibitor,
  ExhibitorStore,
  putExhibitorThunk,
  removeExhibitorThunk,
} from './exhibitorSlice';
import React, { useState, } from 'react';
import { EditExhibitor, } from './apiCalls';
import { useAppDispatch, } from '../../app/hooks';
import ConfirmModal from '../../components/ConfirmModal';
import {
  EDIT_ICON,
  SAVE_CHANGES_ICON,
  TRASH_ICON,
} from '../../common/uiText';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../auth/authSlice';

const ExhibitorRow = ({
  exhibitor,
}: { exhibitor: ExhibitorStore,}) => {
  const jwt = useSelector(selectJwt);
  const dispatch = useAppDispatch();
  const [name, setName] = useState(exhibitor.name);
  const [coords, setCoords] = useState(exhibitor.coords);
  const [url, setUrl] = useState(exhibitor.url);
  const [notes, setNotes] = useState(exhibitor.notes);
  const [modalShow, setModalShow] = useState(false);

  const editingResponse = <tr>
    <td>{exhibitor.id}</td>
    <td>
      <FormControl
        type="text"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
    </td>
    <td>
      <FormControl
        type="text"
        onChange={(e) => setCoords(e.target.value)}
        value={coords}
      />
    </td>
    <td>{'This will be inferred'}</td>
    <td>
      <FormControl
        type="text"
        onChange={(e) => setUrl(e.target.value)}
        value={url}
      />
    </td>
    <td>
      <FormControl
        type="text"
        onChange={(e) => setNotes(e.target.value)}
        value={notes}
      />
    </td>
    <td><Button disabled>{TRASH_ICON}</Button></td>
    <td>
      <Button
        variant="success"
        onClick={() => {
          const strippedCoords = coords.replace(/\s/g, '');

          const qp: EditExhibitor = {
            name,
            coords: strippedCoords,
            url,
            notes
          };
          dispatch(putExhibitorThunk({
            qp,
            jwt,
            id: exhibitor.id
          }));
        }}
      >{SAVE_CHANGES_ICON}</Button>
    </td>
  </tr>;

  const nonEditingResponse = <>
      <tr>
        <td>{exhibitor.id}</td>
        <td>{exhibitor.name}</td>
        <td>{exhibitor.coords}</td>
        <td>{[exhibitor.city, exhibitor.state, exhibitor.country].filter(s => s !== '').join(', ')}</td>
        <td>{exhibitor.url}</td>
        <td>{exhibitor.notes}</td>
        <td><Button onClick={() => setModalShow(true)}>{TRASH_ICON}</Button></td>
        <td>
          <Button
            onClick={() => dispatch(editExhibitor(exhibitor.id))}
          >{EDIT_ICON}</Button>
        </td>
      </tr>
      <ConfirmModal
        show={modalShow}
        onConfirm={() => {
          const qp = { id: exhibitor.id };
          dispatch(removeExhibitorThunk({
            jwt,
            qp
          }));
          setModalShow(false);
        }}
        onCancel={() => setModalShow(false)}
        headText={`You are about to delete '${exhibitor.name || `id: ${exhibitor.id}`}'!`}
        bodyText={'Once you delete an exhibitor, you cannot undo this!'}
      />
    </>
  ;

  return exhibitor.isEditing ? editingResponse : nonEditingResponse;
};

export default ExhibitorRow;
