import React, { useState, } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from 'react-bootstrap';
import { useAppDispatch, } from '../../app/hooks';
import {
  putDateThunk,
  selectLandscapeDateImageId,
  selectPortraitDateImageId,
  setCurrentLandscapeImageId,
  setCurrentPortraitImageId,
} from './dateSlice';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../auth/authSlice';
import {
  getImagesThunk,
  selectFullImagesList,
  SingleImage,
} from '../image/imageSlice';
import { createToast, } from '../toasts/toastSlice';

const ImageSelectionDropdown = ({
  id,
  filter,
  selectionAction,
}: {
  id: number,
  filter: (img: SingleImage) => boolean,
  selectionAction: (n: number) => void,
}) => {
  const dispatch = useAppDispatch();
  const jwt = useSelector(selectJwt);
  const images = useSelector(selectFullImagesList);

  return <Form.Select
    aria-label={'Image'}
    onChange={(e) => {
      const imgId = Number(e.target.value);
      selectionAction(imgId);
    }
    }
    onClick={() => dispatch(getImagesThunk({
      qp: {},
      jwt
    }))}
    value={id}
  >
    <option key={'none'}>None</option>
    {
      images.filter(filter).map(i => {
        return (
          <option
            value={i.id}
            key={i.id}>
            {i.id}
          </option>
        );
      })
    }
  </Form.Select>;
};

const DateItemSelector = () => {
  const dispatch = useAppDispatch();
  const jwt = useSelector(selectJwt);
  const [date, setDate] = useState('');
  const portraitImg = useSelector(selectPortraitDateImageId);
  const landscapeImg = useSelector(selectLandscapeDateImageId);

  const submit = () => {
    if (!date || portraitImg === -1 || landscapeImg === -1) {
      dispatch(createToast({
        title: 'Unable to add date assignment',
        details: 'Please select a valid date and assign an image to both the landscape and the portrait image fields to add entry',
        type: 'Secondary'
      }));
    } else {
      setDate('');
      // otherwise call the edit date action
      dispatch(putDateThunk({
        jwt,
        date,
        portrait_img: portraitImg,
        landscape_img: landscapeImg,
      }));
      dispatch(setCurrentLandscapeImageId(-1));
      dispatch(setCurrentPortraitImageId(-1));
    }
  };

  return <Container className="p-3">
    <Row><h2>Image Date Assignment</h2>
    </Row>
    <Row>
      <Col><h5>Date</h5></Col>
      <Col><h5>Portrait Image</h5></Col>
      <Col><h5>Landscape Image</h5></Col>
      <Col></Col>
    </Row>
    <Row>
      <Col>
        <FormControl
          type={'date'}
          name={'assigned date'}
          placeholder={'Choose a specific date'}
          value={date}
          onChange={(e) => {
            // Note: The following date parsing will parse it to UTC date, at midnight.
            // For example, passing "1999-12-31" in central time zone would actually make it off by one and be parsed as
            // December 30, So the date.toUTCString should be used.
            const newDate = new Date(e.target.value);
            const dateString = newDate.toISOString().split('T')[0];
            console.log(dateString);
            setDate(dateString);
          }}
        />
      </Col>
      <Col>
        {/*   This is portrait image */}
        <ImageSelectionDropdown
          id={portraitImg}
          filter={(img: SingleImage) => img.portrait}
          selectionAction={(num) => dispatch(setCurrentPortraitImageId(num))}
        />
      </Col>
      <Col>
        {/*   This is landscape image */}
        <ImageSelectionDropdown
          id={landscapeImg}
          filter={(img: SingleImage) => !img.portrait}
          selectionAction={(num) => dispatch(setCurrentLandscapeImageId(num))}
        />
      </Col>
      <Col>
        <Button
          onClick={submit}
        >Submit</Button>
      </Col>
    </Row>
  </Container>;
};

export default DateItemSelector;
