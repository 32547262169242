import React, { FormEventHandler, useState, } from 'react';
import { Button, Form, } from 'react-bootstrap';
import { useAppDispatch, } from '../../app/hooks';
import { putAdminThunk, } from './profileSlice';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../auth/authSlice';

const AuthorizeUserForm = () => {
  const [newUID, setNewUID] = useState('');
  const jwt = useSelector(selectJwt);
  const dispatch = useAppDispatch();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    dispatch(putAdminThunk({
      jwt,
      uid: newUID
    }));
    setNewUID('');
  };
  return (
    <>
      <h2>
        Authorize a new User
      </h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="newUserId">
          <Form.Label>New Authorized User ID</Form.Label>
          <Form.Control
            type="text"
            value={newUID}
            placeholder="User ID"
            onChange={e => setNewUID(e.target.value)}
          />
          <Form.Text className="text-muted">
            To find this, look at the table below.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default AuthorizeUserForm;
