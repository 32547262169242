import {
  Container,
  Nav,
  Navbar,
} from 'react-bootstrap';
import React from 'react';
import {
  ActiveItem,
  changeActiveItem,
  selectActiveItem,
} from './navSlice';
import { useAppDispatch, } from '../../app/hooks';
import { getSpeciesThunk, } from '../species/speciesSlice';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../auth/authSlice';
import { getExhibitorThunk, } from '../exhibitor/exhibitorSlice';
import { getImagesThunk, } from '../image/imageSlice';
import { getAdminsThunk, getUserIdThunk, } from '../profile/profileSlice';
import { getDateThunk, } from '../date/dateSlice';
import { getAboutThunk, } from '../home/homeSlice';

export const BRAND = 'Butterfly Admin';
export const HOME = 'Home';
export const AUTH = 'Authenticate';
export const PROFILE = 'Profile';
export const SPECIES = 'Species';
export const EXHIBITOR = 'Exhibitor';
export const IMAGES = 'Images';
export const DAYS = 'Days';
export const UPLOAD = 'Upload';

const ButterflyNav = () => {
  const dispatch = useAppDispatch();
  const activeKey = useSelector(selectActiveItem);
  const jwt = useSelector(selectJwt);
  return <Navbar expand="lg" className="bg-body-tertiary">
    <Container>
      <Navbar.Brand>{BRAND}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          variant="tabs"
          className="me-auto"
          activeKey={activeKey}
          onSelect={(selectedKey) => {
            const key = Number(selectedKey);
            dispatch(changeActiveItem(key));
          }}
          defaultActiveKey={ActiveItem.Home}
        >
          <Nav.Item>
            <Nav.Link
              eventKey={ActiveItem.Home}
              onClick={() => {
                dispatch(getAboutThunk());
              }}
            >{HOME}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={ActiveItem.Auth}>{AUTH}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={ActiveItem.Profile}
              onClick={() => {
                if (jwt) {
                  dispatch(getUserIdThunk({ jwt }));
                  dispatch(getAdminsThunk({ jwt }));
                }
              }}
            >{PROFILE}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={ActiveItem.Species}
              onClick={() => {
                if (jwt) {
                  dispatch(getSpeciesThunk({ jwt }));
                }
              }}
            >{SPECIES}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={ActiveItem.Exhibitor}
              onClick={() => {
                if (jwt) {
                  dispatch(getExhibitorThunk({
                    qp: {},
                    jwt
                  }));
                }
              }}
            >{EXHIBITOR}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={ActiveItem.Images}
              onClick={() => {
                if (jwt) {
                  dispatch(getImagesThunk({
                    qp: {},
                    jwt
                  }));
                  dispatch(getExhibitorThunk({
                    qp: {},
                    jwt
                  }));
                  dispatch(getSpeciesThunk({ jwt }));
                }
              }}
            >{IMAGES}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={ActiveItem.Days}
              onClick={() => {
                if (jwt) {
                  dispatch(getDateThunk({ jwt }));
                }
              }}
            >{DAYS}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={ActiveItem.Upload}>{UPLOAD}</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
};

export default ButterflyNav;
