import { webPost, } from './webClient';
import { BadRequestError, } from './apiInterfaces';

export interface AuthorizeCallData {
  email: string;
  password: string;
}

export interface AuthorizeCallResponse {
  access_token: string;
  expires_in: number;
  expires_at: number;
  refresh_token: string;
  token_type: string;
}

export interface RegisterCallResponse {
  uid: string,
  email: string,
}

export const SIGNUP_PATH = 'signup';
export const LOGIN_PATH = 'login';
export const JWT_KEY = 'token';

const authCall = async (data: AuthorizeCallData, path: string): Promise<AuthorizeCallResponse> => {
  const response = await webPost(path, data);
  const json = await response.json();
  if (response.status >= 400) {
    const d = json.detail;
    throw new BadRequestError(response.status, d);
  }
  return json as AuthorizeCallResponse;
};
/**
 * attempts to sign up with the given data, or throws an error if the response is >= 400
 * @param data the users email and password
 * @throws Error if the response is >= 400
 */
export const signupCall = async (data: AuthorizeCallData): Promise<RegisterCallResponse> => {
  const response = await webPost(SIGNUP_PATH, data);
  const json = await response.json();
  if (response.status >= 400) {
    const d = json.detail;
    throw new BadRequestError(response.status, d);
  }
  return json as RegisterCallResponse;
};

/**
 * attempts to log in with the given data, or throws an error if the response is >= 400
 * @param data the users email and password
 * @throws Error if the response is >= 400
 */
export const loginCall = async (data: AuthorizeCallData): Promise<AuthorizeCallResponse> => {
  return authCall(data, LOGIN_PATH);
};

/**
 * Sets the json web token as a local cookie.
 */
export const setJWT = (data: AuthorizeCallResponse) => {
  sessionStorage.setItem(JWT_KEY, data.access_token);
  console.log('set jwt: ', data.access_token);
};

/**
 * Returns the session's jwt that is set as a local cookie, or null if it's not set
 * @return string - if the jwt has been set
 * @return null - if the jwt has not been set
 */
export const getJWT = (): string | null => {
  const jwt = sessionStorage.getItem(JWT_KEY);
  console.log('getting jwt: ', jwt);
  return jwt;
};
