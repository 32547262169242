import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';
import {
  AddExhibitor,
  addExhibitorCall,
  AddExhibitorResponse,
  EditExhibitor,
  Exhibitor,
  GetDelExhibitor,
  getExhibitorCall,
  putExhibitorCall,
  removeExhibitorCall,
} from './apiCalls';
import { BadRequestError, } from '../../webc/apiInterfaces';

export interface ExhibitorStore extends Exhibitor {
  isEditing: boolean,
}

export enum ExhibitorStatus {
  Pending,
  Fulfilled,
  Uninitialized,
  Unauthorized,
  Rejected,
}
export interface ExhibitorSlice {
  exhibitors: ExhibitorStore[],
  status: ExhibitorStatus,
}

const initialState: ExhibitorSlice = {
  exhibitors: [],
  status: ExhibitorStatus.Uninitialized,
};
const exhibitorSorter = (a: Exhibitor, b: Exhibitor) => a.id - b.id;
export const removeExhibitorThunk = createAsyncThunk(
  'exhibitors/remove',
  async ({
    qp,
    jwt
  }: { qp: GetDelExhibitor, jwt: string }, { rejectWithValue }) => {
    try {
      return await removeExhibitorCall(qp, jwt);
    } catch (error) {
      const e = error as BadRequestError;
      return rejectWithValue({ code: e.code, detail: e.detail });
    }
  });
export const addExhibitorThunk = createAsyncThunk(
  'exhibitors/add',
  async ({
    qp,
    jwt
  }: { qp: AddExhibitor, jwt: string }, { rejectWithValue }) => {
    try {
      return await addExhibitorCall(qp, jwt);
    } catch (error) {
      return rejectWithValue(qp.name);
    }
  });
export const getExhibitorThunk = createAsyncThunk(
  'exhibitors/get',
  async ({
    qp,
    jwt
  }: { qp: GetDelExhibitor, jwt: string }, { rejectWithValue }) => {
    try {
      return await getExhibitorCall(qp, jwt);
    } catch (error) {
      return rejectWithValue(qp.name);
    }
  });
export const putExhibitorThunk = createAsyncThunk(
  'exhibitors/edit',
  async ({
    qp,
    jwt,
    id
  }: { qp: EditExhibitor, jwt: string, id: number }, { rejectWithValue }) => {
    try {
      return await putExhibitorCall(qp, jwt, id);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const exhibitorSlice = createSlice({
  name: 'exhibitors',
  initialState,
  reducers: {
    editExhibitor: (state, action: PayloadAction<number>) => {
      const exhibitorIndex = state.exhibitors.findIndex(e => e.id === action.payload);
      if (exhibitorIndex > -1) {
        state.exhibitors[exhibitorIndex].isEditing = true;
        console.log('now editing');
      } else {
        console.error('exhibitor not found');
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeExhibitorThunk.fulfilled, (state, action: PayloadAction<AddExhibitorResponse[]>) => {
        state.exhibitors = state.exhibitors.filter(e => action.payload.every(removedExhibitor => e.id !== removedExhibitor.id));
        state.exhibitors.sort(exhibitorSorter);
      })
      .addCase(addExhibitorThunk.fulfilled, (state, action: PayloadAction<AddExhibitorResponse>) => {
        state.exhibitors.push({
          isEditing: false,
          ...action.payload,
        });
        state.exhibitors.sort(exhibitorSorter);
      })
      .addCase(getExhibitorThunk.fulfilled, (state, action: PayloadAction<AddExhibitorResponse[]>) => {
        state.exhibitors = action.payload.map(e => {
          return {
            isEditing: false,
            ...e,
          };
        });
        state.exhibitors.sort(exhibitorSorter);
        state.status = ExhibitorStatus.Fulfilled;
      })
      .addCase(getExhibitorThunk.pending, (state) => {
        state.status = ExhibitorStatus.Pending;
      })
      .addCase(getExhibitorThunk.rejected, (state, action) => {
        console.log(action);
        if (Number(action.error.code) === 401) {
          state.status = ExhibitorStatus.Unauthorized;
        } else {
          state.status = ExhibitorStatus.Rejected;
        }
      })
      .addCase(putExhibitorThunk.fulfilled, (state, action) => {
        const exhibitorId = state.exhibitors.findIndex(e => e.id === action.payload?.id);
        if (exhibitorId > -1 && action.payload) {
          state.exhibitors[exhibitorId] = {
            isEditing: false,
            ...action.payload,
          };
        }
      })
    ;
  }
});

export const { editExhibitor } = exhibitorSlice.actions;
export const exhibitorsListSelector = (state: RootState): ExhibitorStore[] => state.exhibitors.exhibitors;
export const exhibitorsStatusSelector = (state: RootState): ExhibitorStatus => state.exhibitors.status;
export default exhibitorSlice.reducer;
