import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import {
  deleteAdminCall,
  deleteUserCall,
  getAdminCall,
  GetAdminResponse,
  getUserIdCall,
  putAdminCall,
} from './apiCalls';
import { RootState, } from '../../app/store';

interface ProfileSlice {
  userId: string,
  admins: GetAdminResponse[],
}

const initialState: ProfileSlice = {
  userId: '',
  admins: []
};

export const getUserIdThunk = createAsyncThunk(
  'profile/getUID',
  async ({ jwt }: { jwt: string }, { rejectWithValue }) => {
    try {
      return await getUserIdCall(jwt);
    } catch (e) {
      return rejectWithValue(jwt);
    }
  }
);

export const deleteAccountThunk = createAsyncThunk(
  'profile/deleteAccount',
  async ({
    jwt,
    uid
  }: { jwt: string, uid: string }, { rejectWithValue }) => {
    try {
      return await deleteUserCall(jwt, uid);
    } catch (e) {
      return rejectWithValue(jwt);
    }
  }
);

export const getAdminsThunk = createAsyncThunk(
  'profile/getAdmin',
  async ({
    jwt,
  }: { jwt: string, }, { rejectWithValue }) => {
    try {
      return await getAdminCall(jwt);
    } catch (e) {
      return rejectWithValue(jwt);
    }
  }
);
export const putAdminThunk = createAsyncThunk(
  'profile/putAdmin',
  async ({
    jwt,
    uid
  }: { jwt: string, uid: string }, { rejectWithValue }) => {
    try {
      return await putAdminCall(jwt, uid);
    } catch (e) {
      return rejectWithValue(jwt);
    }
  }
);
export const deleteAdminThunk = createAsyncThunk(
  'profile/deleteAdmin',
  async ({
    jwt,
    uid
  }: { jwt: string, uid: string }, { rejectWithValue }) => {
    try {
      return await deleteAdminCall(jwt, uid);
    } catch (e) {
      return rejectWithValue(jwt);
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserIdThunk.fulfilled, (state, action) => {
        state.userId = action.payload.id;
      })
      .addCase(deleteAccountThunk.fulfilled, (state, action) => {
        state.userId = '';
      })
      .addCase(getAdminsThunk.fulfilled, (state, action) => {
        state.admins = action.payload;
      })
      .addCase(putAdminThunk.fulfilled, (state, action) => {
        const changedAdmin = action.payload;
        const index = state.admins.findIndex(a => a.id === changedAdmin.id);
        if (index >= 0) {
          state.admins[index].admin = true;
        }
      })
      .addCase(deleteAdminThunk.fulfilled, (state, action) => {
        const changedAdmin = action.payload;
        const index = state.admins.findIndex(a => a.id === changedAdmin.id);
        if (index >= 0) {
          state.admins[index].admin = false;
        }
      })
    ;
  }
});

export const selectUserId = (state: RootState) => state.profile.userId;
export const selectAdmins = (state: RootState) => state.profile.admins;

export default profileSlice.reducer;
