import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  AddSpecies,
  addSpeciesCall,
  getSpeciesCall,
  putSpeciesCall,
  RemoveSpecies,
  removeSpeciesCall,
  RemoveSpeciesResponse,
  Species,
  SpeciesQueryParams,
} from './apiCalls';
import { RootState, } from '../../app/store';

export interface SpeciesStore extends Species {
  isEditing: boolean,
}
export enum SpeciesStatus {
  Pending,
  Fulfilled,
  Rejected,
  Uninitialized,
}
export interface SpeciesSlice {
  butterflySpecies: SpeciesStore[];
  status: SpeciesStatus,
}

const initialState: SpeciesSlice = {
  butterflySpecies: [],
  status: SpeciesStatus.Uninitialized,
};

export const getSpeciesThunk = createAsyncThunk(
  'species/fetchAll',
  async (qp: SpeciesQueryParams) => {
    const response = await getSpeciesCall(qp);
    return response;
  }
);
export const addSpeciesThunk = createAsyncThunk(
  'species/add',
  async ({
    jwt,
    qp
  }: { jwt: string, qp: AddSpecies }) => {
    return await addSpeciesCall(jwt, qp);
  }
);
export const removeSpeciesThunk = createAsyncThunk(
  'species/remove',
  async (qp: RemoveSpecies) => {
    return await removeSpeciesCall(qp);
  }
);
export const editSpeciesThunk = createAsyncThunk(
  'species/edit',
  async ({
    qp,
    jwt,
    id
  }: { qp: AddSpecies, jwt: string, id: number }, { rejectWithValue }) => {
    try {
      return await putSpeciesCall(qp, jwt, id);
    } catch (error) {
      return rejectWithValue({
        qp,
        id
      });
    }
  }
);

export const speciesSlice = createSlice({
  name: 'species',
  initialState,
  reducers: {
    editButterfly: (state, action: PayloadAction<number>) => {
      const butterflyIndex = state.butterflySpecies.findIndex(b => b.id === action.payload);
      if (butterflyIndex > -1) {
        state.butterflySpecies[butterflyIndex].isEditing = true;
      }
    }
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(getSpeciesThunk.fulfilled, (state, action: PayloadAction<Species[]>) => {
          state.butterflySpecies = action.payload.map(b => {
            return {
              isEditing: false,
              ...b
            };
          });
          state.status = SpeciesStatus.Fulfilled;
        })
        .addCase(getSpeciesThunk.pending, (state) => {
          state.status = SpeciesStatus.Pending;
        })
        .addCase(getSpeciesThunk.rejected, (state) => {
          state.status = SpeciesStatus.Rejected;
        })
        .addCase(addSpeciesThunk.fulfilled, (state, action: PayloadAction<Species>) => {
          state.butterflySpecies.push({
            isEditing: false,
            ...action.payload,
          });
          state.butterflySpecies.sort((a, b) => {
            return a.id - b.id;
          });
        })
        .addCase(removeSpeciesThunk.fulfilled, (state, action: PayloadAction<RemoveSpeciesResponse[]>) => {
          state.butterflySpecies = state.butterflySpecies.filter(s => action.payload.every(removedSpecies => s.id !== removedSpecies.id));
        })
        .addCase(editSpeciesThunk.fulfilled, (state, action) => {
          const bfIndex = state.butterflySpecies.findIndex(bf => bf.id === action.payload.id);
          if (bfIndex > -1) {
            state.butterflySpecies[bfIndex] = {
              isEditing: false,
              ...action.payload
            };
          }
        })
      ;
    }

});

export const speciesListSelector = (state: RootState): SpeciesStore[] => state.species.butterflySpecies;
export const speciesStatusSelector = (state: RootState): SpeciesStatus => state.species.status;
export const { editButterfly } = speciesSlice.actions;
export default speciesSlice.reducer;
