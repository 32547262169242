import React from 'react';
import ButterflyNav from './features/nav/ButterflyNav';
import { useSelector, } from 'react-redux';
import { selectActiveItem, } from './features/nav/navSlice';
import CurrentlyActiveItem from './components/ActiveItem';
import Toasts from './features/toasts/Toasts';

const App = () => {
  const state = useSelector(selectActiveItem);

  return (
    <div className="App">
      <ButterflyNav />
      <CurrentlyActiveItem state={state} />
      <Toasts />
    </div>
  );
};

export default App;
