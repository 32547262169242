/**
 * Compute the wagner-fischer distance from two strings. Copied from: https://github.com/tallesl/wagner-fischer
 * @param str1
 * @param str2
 */
export const wagnerFischer = (str1: string, str2: string) => {
  const distances: number[][] = [];

  for (let i = 0; i <= str1.length; ++i) distances[i] = [i];
  for (let i = 0; i <= str2.length; ++i) distances[0][i] = i;

  for (let j = 1; j <= str2.length; ++j) {
    for (let i = 1; i <= str1.length; ++i) {
      distances[i][j] =
        str1[i - 1] === str2[j - 1] // if the characters are equal
          ? distances[i - 1][j - 1] // no operation needed
        // else
          : Math.min(...[ // take the minimum between
            distances[i - 1][j] + 1, // a  deletion
            distances[i][j - 1] + 1, // an insertion
            distances[i - 1][j - 1] + 1 // a  substitution
          ]);
    }
  }
  return distances[str1.length][str2.length];
};
