import {
  webDelete,
  webGet,
  webPut,
} from '../../webc/webClient';
import { Species, } from '../species/apiCalls';
import { Exhibitor, } from '../exhibitor/apiCalls';

export interface SingleImageResponse {
  id: number,
  species: Species,
  exhibitor: Exhibitor,
  parent: number,
  children: number[]
  url: string,
  x: number,
  y: number,
  portrait: boolean,
  thumbnail_url?: string,
}

export interface GetImageCallParams {
  id?: number,
  species_id?: number,
  exhibitor_id?: number,
  filename?: string,
}

export interface EditImageParams {
  portrait: boolean,
  species?: number,
  exhibitor?: number,
  parent?: string,
  x?: number,
  y?: number,
}

const IMAGE_PATH = 'image';
export const getImageCall = async (qp: GetImageCallParams, jwt: string): Promise<SingleImageResponse[]> => {
  const response = await webGet(IMAGE_PATH, { jwt, ...qp });
  const json = await response.json();
  if (response.status >= 400) {
    throw Error('error');
  }
  return json as SingleImageResponse[];
};

export const deleteImageCall = async (id: number, jwt: string): Promise<SingleImageResponse[]> => {
  const response = await webDelete(IMAGE_PATH, {
    jwt,
    id
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw Error('error');
  }
  return json as SingleImageResponse[];
};

export const editImageCall = async (editingImage: EditImageParams, jwt: string, id: number): Promise<SingleImageResponse> => {
  const path = `${IMAGE_PATH}?jwt=${jwt}&id=${id}`;
  const response = await webPut(path, editingImage);
  const json = await response.json();
  if (response.status >= 400) {
    throw Error('error');
  }
  return json as SingleImageResponse;
};
