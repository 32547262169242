import {
  Table,
  FormControl,
  Form,
} from 'react-bootstrap';
import { useSelector, } from 'react-redux';
import {
  updateFilter,
  disableFilter,
  enableFilter,
  FilterParams,
  selectImagesViewList,
  selectFilterIsEnabled,
  selectIsImageRequestPending,
} from './imageSlice';
import React, { useState, } from 'react';
import { useAppDispatch, } from '../../app/hooks';
import { selectJwt, } from '../auth/authSlice';
import ImageRow from './ImageRow';
import { PENDING_SPINNER, } from '../../common/uiText';

const ImageTable = () => {
  const dispatch = useAppDispatch();
  const jwt = useSelector(selectJwt);
  const images = useSelector(selectImagesViewList);
  const filterIsEnabled = useSelector(selectFilterIsEnabled);
  const requestIsPending = useSelector(selectIsImageRequestPending);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    id: -1,
    filename: '',
    scientificName: '',
    commonName: '',
    exhibitorName: '',
  });

  return <Table striped hover responsive className={'imageTable'}>
    <thead>
    <tr key="header">
      <th>Image Id</th>
      <th>Image</th>
      <th>Image Center Point in Pixels (x, y)</th>
      <th>Orientation</th>
      <th>Scientific Name</th>
      <th>Common Name</th>
      <th>Exhibitor</th>
      <th>{/* used for '-' button for removing image */}</th>
      <th>{/* used for 'edit' button */}</th>
    </tr>
    </thead>
    <tbody>
    <tr key="filter">
      <td>
        <FormControl
          placeholder={'Filter id'}
          type="number"
          value={filterParams.id > -1 ? filterParams.id : 'Filter id'}
          onChange={(e) => {
            const newFilter: FilterParams = { ...filterParams };
            if (e.target.value) {
              newFilter.id = Number(e.target.value);
            } else {
              newFilter.id = -1;
            }
            setFilterParams(newFilter);
            dispatch(updateFilter(newFilter));
          }}
        />
      </td>
      <td>{/* image column */}</td>
      <td>{/* image center point */}</td>
      <td>{/* image is portrait */}</td>
      <td>
        <FormControl
          placeholder={'Filter scientific name'}
          type="text"
          value={filterParams.scientificName}
          onChange={(e) => {
            const newFilter: FilterParams = { ...filterParams };
            if (e.target.value) {
              newFilter.scientificName = e.target.value;
            } else {
              newFilter.scientificName = '';
            }
            setFilterParams(newFilter);
            dispatch(updateFilter(newFilter));
          }}
        />
      </td>
      <td>
        <FormControl
          placeholder={'Filter common name'}
          type="text"
          value={filterParams.commonName}
          onChange={(e) => {
            const newFilter: FilterParams = { ...filterParams };
            if (e.target.value) {
              newFilter.commonName = e.target.value;
            } else {
              newFilter.commonName = '';
            }
            setFilterParams(newFilter);
            dispatch(updateFilter(newFilter));
          }}
        />
      </td>
      <td>
        <FormControl
          placeholder={'Filter exhibitor'}
          type="text"
          value={filterParams.exhibitorName}
          onChange={(e) => {
            const newFilter: FilterParams = { ...filterParams };
            if (e.target.value) {
              newFilter.exhibitorName = e.target.value;
            } else {
              newFilter.exhibitorName = '';
            }
            setFilterParams(newFilter);
            dispatch(updateFilter(newFilter));
          }}
        />
      </td>
      <td>
        <Form.Check
          type="switch"
          label="Filter"
          defaultChecked={filterIsEnabled}
          onClick={() => {
            if (filterIsEnabled) {
              dispatch(disableFilter());
            } else {
              dispatch(enableFilter());
            }
          }
          }
        />
      </td>
      <td></td>
    </tr>
    {
      requestIsPending
        ? <tr><td colSpan={9} align={'center'}>
          {PENDING_SPINNER}
        </td></tr>
        : images.map(image => {
          return <ImageRow
          key={image.id}
          image={image}
          jwt={jwt}
        />;
        })
    }
    </tbody>

  </Table>;
};

export default ImageTable;
