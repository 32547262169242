import React, { FormEventHandler, useState, } from 'react';
import { useSelector, } from 'react-redux';
import {
  putAboutThunk,
  putVideoThunk,
  selectAbout,
  selectDailyLandscape,
  selectVideo,
} from './homeSlice';
import {
  Button,
  Container,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import { useAppDispatch, } from '../../app/hooks';

const DailyImage = () => {
  const dailyLandscape = useSelector(selectDailyLandscape);
  if (!dailyLandscape) {
    return <></>;
  } else {
    return <Container fluid="sm">
      <Row>
        {dailyLandscape.species &&
          <h2>Today&apos;s Daily Image is of the {dailyLandscape.species.scientific_name}.</h2>}
        {dailyLandscape.exhibitor && <h2>Today&apos;s Daily Image was taken at {dailyLandscape.exhibitor.name}.</h2>}
      </Row>
      <Row>
        <Image src={dailyLandscape.url} rounded />
      </Row>
    </Container>;
  }
};
const AboutVideo = () => {
  const videoUrl = useSelector(selectVideo);

  if (!videoUrl) {
    return <></>;
  } else {
    return <Container fluid="sm">
      <Row>
        <h2>About Video</h2>
      </Row>
      <Row>
        <iframe
          width="560"
          height="315"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Row>
    </Container>;
  }
};

const Home = ({ jwt }: { jwt: string }) => {
  const dispatch = useAppDispatch();
  const about = useSelector(selectAbout);
  const video = useSelector(selectVideo);
  const [newAbout, setNewAbout] = useState(about);
  const [newVideo, setNewVideo] = useState(video);
  const className = 'p-3';
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (newAbout !== about) {
      dispatch(putAboutThunk({
        jwt,
        body: newAbout
      }));
    }
    if (newVideo !== video) {
      dispatch(putVideoThunk({
        jwt,
        body: newVideo
      }));
    }
  };
  if (jwt) {
    return <div className={className}>
      <Container>
        <Row>
          <h2>Welcome!</h2>
        </Row>
        <hr />
        <Row>
          {<h2>About: &lsquo;{about}&rsquo;</h2>}
        </Row>
        <Row>
          <Form className="p-3" onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3"
              controlId="newAbout">
              <Form.Label>New About</Form.Label>
              <Form.Control
                type="text"
                placeholder="New About"
                value={newAbout}
                onChange={(e) => setNewAbout(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="newVideo">
              <Form.Label>New Video Url</Form.Label>
              <Form.Control
                type="text"
                placeholder="New Video Url"
                value={newVideo}
                onChange={(e) => setNewVideo(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit">
              Submit
            </Button>
          </Form>
        </Row>
      </Container>
      <hr />
      <DailyImage />
      <hr />
      <AboutVideo />
    </div>;
  } else {
    return <div className={className}>
      <Container>
        <Row>
          <h2>Welcome!</h2>
        </Row>
        <hr />
        <Row>
          {about && <><h2>About</h2><h3>{about}</h3></>}
        </Row>
      </Container>
      <hr />
      <DailyImage />
      <hr />
      <AboutVideo />
    </div>;
  }
};

export default Home;
