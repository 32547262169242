import NotLoggedIn from './NotLoggedIn';
import React from 'react';

const LoggedInChecker = ({
  jwt,
  Component
}: { jwt: string, Component: React.ComponentType }) => {
  if (!jwt) {
    return <NotLoggedIn />;
  } else {
    return <Component />;
  }
};

export default LoggedInChecker;
