import React from 'react';
import { createRoot, } from 'react-dom/client';
import { Provider, } from 'react-redux';
import { store, } from './app/store';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import {
  getAboutThunk,
  getDailyImageThunk,
  getVideoThunk,
} from './features/home/homeSlice';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
store.dispatch(getAboutThunk());
store.dispatch(getVideoThunk());
store.dispatch(getDailyImageThunk());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
