import React from 'react';
import { SingleImage, } from './imageSlice';
import {
  Image,
  Button,
  Modal,
} from 'react-bootstrap';

const stringOrNone = (x: any) => {
  return x ? x.toString() : 'none';
};

const ImageDetailsModal = ({
  image,
  show,
  onCancel
}: { image: SingleImage, show: boolean, onCancel: () => void, }) => {
  return <Modal
    // size="xl"
    fullscreen={true}
    centered
    show={show}
    onHide={onCancel}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {'Image Details'}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h3>Image:</h3>
      {/* <ImageCanvas url={image.url} x={image.x} y={image.y} /> */}
      <Image src={image.url} fluid aria-placeholder={'Image'} />
      <hr />
      <h3>Details:</h3>
      <ul>
        <li>{`Id: ${image.id}`}</li>
        <li>Full Size Image URL: <a href={image.url}>{image.url}</a></li>
        <li>Smaller Sized Image URL: <a href={image.thumbnail_url}>{image.thumbnail_url}</a></li>
        <li>{`Orientation: ${image.portrait ? 'Portrait' : 'Landscape'}`}</li>
        <li>{`Parent ID: ${image.parent || 'none'}`}</li>
        <li>{`Children IDs: ${image.children.length > 0 ? image.children.join(' ') : 'none'}`}</li>
        <li>{`Image Center Coordinates (x,y) in pixels: (${image.x || 'none'}, ${image.y || 'none'})`}</li>
        {image.exhibitor && <li>{'Exhibitor'}</li>}
        {image.exhibitor && <ul>
          <li>{`Id: ${image.exhibitor.id}`}</li>
          <li>{`Name: ${stringOrNone(image.exhibitor.name)}`}</li>
          <li>{`Notes: ${stringOrNone(image.exhibitor.notes)}`}</li>
          <li>{`Coordinates: ${stringOrNone(image.exhibitor.coords)}`}</li>
          <li>{`City: ${stringOrNone(image.exhibitor.city)}`}</li>
          <li>{`State: ${stringOrNone(image.exhibitor.state)}`}</li>
          <li>{`Country: ${stringOrNone(image.exhibitor.country)}`}</li>
          <li>{`URL: ${stringOrNone(image.exhibitor.url)}`}</li>
        </ul>}
        {image.species && <li>{'Species'}</li>}
        {image.species && <ul>
          <li>{`Id: ${image.species.id}`}</li>
          <li>{`Scientific Name: ${stringOrNone(image.species.scientific_name)}`}</li>
          <li>{`Common Name: ${stringOrNone(image.species.common_name)}`}</li>
          <li>{`Notes: ${stringOrNone(image.species.notes)}`}</li>
        </ul>}
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onCancel}>Close</Button>
    </Modal.Footer>
  </Modal>;
};

export default ImageDetailsModal;
