import {
  webDelete,
  webGet,
  webPost,
  webPut,
} from '../../webc/webClient';

export interface Species {
  id: number,
  common_name: string,
  scientific_name: string,
  notes: string,
}

export interface AddSpecies {
  scientific_name: string,
  common_name: string
  notes: string,
}

export interface RemoveSpecies {
  jwt: string,
  id: number,
}

export type RemoveSpeciesResponse = Species;

export interface SpeciesQueryParams {
  jwt: string
  id?: number,
  scientific_name?: string,
  common_name?: string,
  notes?: string,
}

const SPECIES_PATH = 'species';

// TODO handle thrown error
export const getSpeciesCall = async (qp: SpeciesQueryParams): Promise<Species[]> => {
  const response = await webGet(SPECIES_PATH, { ...qp });
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as Species[];
};

// TODO handle thrown error
export const addSpeciesCall = async (jwt: string, qp: AddSpecies): Promise<Species> => {
  const path = `${SPECIES_PATH}?jwt=${jwt}`;
  const response = await webPost(path, qp);
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as Species;
};

// TODO handle thrown error
export const removeSpeciesCall = async (qp: RemoveSpecies): Promise<RemoveSpeciesResponse[]> => {
  const response = await webDelete(SPECIES_PATH, { ...qp });
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as RemoveSpeciesResponse[];
};

export const putSpeciesCall = async (qp: AddSpecies, jwt: string, id: number): Promise<Species> => {
  const path = `${SPECIES_PATH}?jwt=${jwt}&id=${id}`;
  const response = await webPut(path, qp);
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as Species;
};
