import {
  webDelete,
  webGet,
  webPut,
} from '../../webc/webClient';
import { BadRequestError, } from '../../webc/apiInterfaces';

const GET_ID = 'get-id';

export interface GetUserIdResponse {
  id: string,
}

export const getUserIdCall = async (jwt: string) => {
  const response = await webGet(GET_ID, { jwt });
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  } else {
    return json as GetUserIdResponse;
  }
};

const DELETE_ACCOUNT = 'delete-account';

interface DeleteAccountResponse {
  id: string;
}

export const deleteUserCall = async (jwt: string, uid: string) => {
  const response = await webDelete(DELETE_ACCOUNT, {
    jwt,
    uid
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  } else {
    return json as DeleteAccountResponse;
  }
};

const ADMIN = 'admin';

interface AdminResponse {
  id: string;
}

export const putAdminCall = async (jwt: string, id: string) => {
  const path = `${ADMIN}?jwt=${jwt}`;
  const response = await webPut(path, {
    id
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  } else {
    return json as AdminResponse;
  }
};

export const deleteAdminCall = async (jwt: string, id: string) => {
  const response = await webDelete(ADMIN, {
    jwt,
    id
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  } else {
    return json as AdminResponse;
  }
};

export interface GetAdminResponse {
  id: string,
  email: string,
  admin: boolean,
}

export const getAdminCall = async (jwt: string) => {
  const response = await webGet(ADMIN, { jwt });
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  } else {
    return json as GetAdminResponse[];
  }
};
