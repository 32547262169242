import { Form, } from 'react-bootstrap';
import React, { ChangeEventHandler, } from 'react';
import { Species, } from '../features/species/apiCalls';

const SpeciesSelector = ({
  onChange,
  species,
  defaultSpeciesId,
}: { onChange: ChangeEventHandler<HTMLSelectElement>, species: Species[], defaultSpeciesId?: number }) => {
  return <Form.Select
    aria-label={'Species'}
    onChange={onChange}
    defaultValue={defaultSpeciesId}
  >
    <option key={'none'}>None (assign later)</option>
    {
      species.map(s => {
        return (
          <option
            value={s.id}
            key={s.id}>
            {s.scientific_name}
          </option>
        );
      })
    }
  </Form.Select>;
};

export default SpeciesSelector;
