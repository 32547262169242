import React, { useState, } from 'react';
import {
  Table,
  Image,
  Button,
} from 'react-bootstrap';
import { useSelector, } from 'react-redux';
import {
  DateItem,
  deleteDateThunk,
  selectDatesList,
} from './dateSlice';
import { useAppDispatch, } from '../../app/hooks';
import { selectJwt, } from '../auth/authSlice';
import { TRASH_ICON, } from '../../common/uiText';
import ConfirmModal from '../../components/ConfirmModal';

const DateRow = ({ dateItem }:{ dateItem: DateItem }) => {
  const jwt = useSelector(selectJwt);
  const dispatch = useAppDispatch();

  const [modalShow, setModalShow] = useState(false);

  // This following date is just used for display purposes. Since the date is parsed as UTC midnight, an offset is added
  const date = new Date(dateItem.date);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return <tr>
    <td>{date.toDateString()}</td>
    <td><Image width={300} src={dateItem.portrait_img.thumbnail_url} /></td>
    <td><Image width={300} src={dateItem.landscape_img.thumbnail_url} /></td>
    <td>
      <Button onClick={() => setModalShow(true)}>
        {TRASH_ICON}
      </Button>
    </td>
    <ConfirmModal
      show={modalShow}
      onConfirm={() => {
        setModalShow(false);
        dispatch(deleteDateThunk({
          jwt,
          date: dateItem.date
        }));
      }}
      onCancel={() => setModalShow(false)}
      headText={'You are about to unset a day!'}
      bodyText={'A random image will instead be selected on the given day unless otherwise assigned. The image will not be deleted.'} />
  </tr>;
};

const DateTable = () => {
  const dates = useSelector(selectDatesList);

  return (<Table striped>
    <thead>
    <tr>
      <th>Day</th>
      <th>Portrait Image</th>
      <th>Landscape Image</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    {
      dates.map(d => <DateRow dateItem={d} key={d.date + d.portrait_img.id} />)
    }
    </tbody>

  </Table>);
};

export default DateTable;
