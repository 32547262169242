import {
  Button,
  FormControl,
  Image,
} from 'react-bootstrap';
import {
  deleteImageThunk,
  editImage,
  saveEditingImageThunk,
  SingleImage,
} from './imageSlice';
import React, { useState, } from 'react';
import { useAppDispatch, } from '../../app/hooks';
import SpeciesSelector from '../../components/SpeciesSelector';
import ExhibitorSelector from '../../components/ExhibitorSelector';
import { useSelector, } from 'react-redux';
import { speciesListSelector, } from '../species/speciesSlice';
import { exhibitorsListSelector, } from '../exhibitor/exhibitorSlice';
import { EditImageParams, } from './apiCalls';
import {
  EDIT_ICON,
  PENDING_SPINNER,
  SAVE_CHANGES_ICON,
  TRASH_ICON,
} from '../../common/uiText';
import OrientationSelector from '../../components/OrientationSelector';
import ConfirmModal from '../../components/ConfirmModal';
import ImageDetailsModal from './ImageDetailsModal';
import { setCurrentLandscapeImageId, setCurrentPortraitImageId, } from '../date/dateSlice';
import { createToast, } from '../toasts/toastSlice';

enum ImageModal {
  Deletion,
  Details,
  None,
}

const ImageCenter = (x: number | undefined, y: number | undefined) => {
  return x && y ? `${x}, ${y}` : '';
};
const strippedCoordsAreInvalid = (strippedCoords: string) => {
  return (strippedCoords.length > 0 && !strippedCoords.includes(',')) || // also handles the case of more than 1 comma
    !/^\d+$/.test(strippedCoords.replace(',', '')); // includes non-digit characters
};

const ImageRow = ({
  image,
  jwt,
}: {
  image: SingleImage,
  jwt: string,
}) => {
  const dispatch = useAppDispatch();
  const speciesList = useSelector(speciesListSelector);
  const exhibitorsList = useSelector(exhibitorsListSelector);
  const [species, setSpecies] = useState(image.species?.id);
  const [exhibitor, setExhibitor] = useState(image.exhibitor?.id);
  const [isPortrait, setIsPortrait] = useState(image.portrait);
  const [modalShow, setModalShow] = useState(ImageModal.None);
  const [coords, setCoords] = useState(ImageCenter(image.x, image.y));
  const editingRet = <tr>
    <td>{image.id}</td>
    <td>
      <Image
        src={image.thumbnail_url || image.url}
        width="300"
        height="auto"
        thumbnail
      />
    </td>
    <td>
      <FormControl
        type="text"
        onChange={(e) => {
          setCoords(e.target.value);
        }}
        value={coords}
      />
    </td>
    <td><OrientationSelector isPortrait={isPortrait} onChange={() => setIsPortrait(!isPortrait)} /></td>
    <td colSpan={2}>
      <SpeciesSelector
        onChange={(e) => setSpecies(Number(e.target.value))}
        species={speciesList}
        defaultSpeciesId={image.species?.id}
      />
    </td>
    <td>
      <ExhibitorSelector
        onChange={(e) => setExhibitor(Number(e.target.value))}
        exhibitors={exhibitorsList}
        defaultExhibitorId={image.exhibitor?.id}
      />
    </td>
    <td><Button disabled>{TRASH_ICON}</Button></td>
    <td>
      {
        image.isPending
          ? PENDING_SPINNER
          : <Button
            variant="success"
            onClick={() => {
              // validating new coordinates input
              const strippedCoords = coords.replace(/\s/g, '');
              let x;
              let y;
              if (strippedCoordsAreInvalid(strippedCoords)) {
                dispatch(createToast({
                  title: 'Unable to set new image center point',
                  details: 'The image center point must be two numbers separated by a comma. The image center point has not been changed.',
                  type: 'Danger',
                }));
                return;
              } else if (strippedCoords.length > 0) {
                x = Number(strippedCoords.split(',')[0]);
                y = Number(strippedCoords.split(',')[1]);
              }

              // sending request to save changes
              const editingImageId = image.id;
              const editingImageParams: EditImageParams = {
                exhibitor,
                species,
                portrait: isPortrait,
                x,
                y,
              };
              dispatch(saveEditingImageThunk({
                jwt,
                editingImageParams,
                editingImageId,
              }));
            }}
          >
            {SAVE_CHANGES_ICON}
          </Button>
      }
    </td>
  </tr>;

  const nonEditingRet = <tr>
    <td><h3 className="p-3">{image.id}</h3>
      <Button
        className="mb-3"
        onClick={() => {
          if (image.portrait) {
            dispatch(setCurrentPortraitImageId(image.id));
          } else {
            dispatch(setCurrentLandscapeImageId(image.id));
          }
          dispatch(createToast({
            details: 'Go to the \'Days\' tab to complete the selection',
            title: 'Successfully queued image selection',
            type: 'Secondary'
          }));
        }}

      >Use this Image!</Button>
    </td>
    <td>
      <Image
        src={image.thumbnail_url || image.url}
        width="300"
        height="auto"
        onClick={() => setModalShow(ImageModal.Details)}
        thumbnail
      />
      {/* <ImageCanvas url={image.url} x={image.x} y={image.y} /> */}
    </td>
    <td>{ImageCenter(image.x, image.y)}</td>
    <td>{isPortrait ? 'Portrait' : 'Landscape'}</td>
    <td>{image.species?.scientific_name}</td>
    <td>{image.species?.common_name}</td>
    <td>{image.exhibitor?.name}</td>
    <td>
      {image.isPending
        ? PENDING_SPINNER
        : <Button onClick={() => setModalShow(ImageModal.Deletion)}>{TRASH_ICON}</Button>}
    </td>
    <td>
      <Button
        onClick={() => {
          dispatch(editImage(image.id));
        }}
      >
        {EDIT_ICON}
      </Button>
    </td>
    <ConfirmModal
      show={modalShow === ImageModal.Deletion}
      onConfirm={() => {
        setModalShow(ImageModal.None);
        dispatch(deleteImageThunk({
          id: image.id,
          jwt
        }));
      }}
      onCancel={() => setModalShow(ImageModal.None)}
      headText={`You are about to delete an image (Id ${image.id})!`}
      bodyText={'Once deleted, this cannot be undone!'}
    />
    <ImageDetailsModal
      image={image}
      show={modalShow === ImageModal.Details}
      onCancel={() => setModalShow(ImageModal.None)}
    />
  </tr>;

  return image.isEditing ? editingRet : nonEditingRet;
};

export default ImageRow;
