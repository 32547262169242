import { wagnerFischer, } from './wagnerfischer';

export const SEARCH_DIST = 2;

/**
 * Used to search for a string. First makes the strings equal in length if the search string is shorter.
 * Then it will find the wagner-fischer distance between the two strings.
 * Returns true if the distance is less than the const SEARCH_DIST
 * If the search string is empty or undefined, it will match all strings
 * If the fullString is undefined, it will match no strings
 * Case insensitive
 * @param fullString
 * @param searchString
 */
export const stringSearch = (fullString: string, searchString?: string): boolean => {
  let substring: string;
  if (!searchString) {
    return true;
  }
  if (!fullString) {
    return false;
  }
  if (searchString.length < fullString.length) {
    substring = fullString.substring(0, searchString.length);
  } else {
    substring = fullString;
  }
  const wfDist = wagnerFischer(substring.toLowerCase(), searchString.toLowerCase());

  return wfDist < SEARCH_DIST;
};
