import {
  editButterfly,
  editSpeciesThunk,
  removeSpeciesThunk,
  SpeciesStore,
} from './speciesSlice';
import { Button, Form, } from 'react-bootstrap';
import React, { ChangeEvent, useState, } from 'react';
import { useAppDispatch, } from '../../app/hooks';
import { AddSpecies, } from './apiCalls';
import ConfirmModal from '../../components/ConfirmModal';
import {
  EDIT_ICON,
  SAVE_CHANGES_ICON,
  TRASH_ICON,
} from '../../common/uiText';

const SpeciesRow = ({
  species,
  jwt
}: { species: SpeciesStore, jwt: string }) => {
  const dispatch = useAppDispatch();
  const [cn, setCn] = useState(species.common_name);
  const [sn, setSn] = useState(species.scientific_name);
  const [notes, setNotes] = useState(species.notes);
  const [modalShow, setModalShow] = useState(false);
  const editingResponse = <tr>
    <td></td>
    <td>
      <Form.Control
        type="text"
        value={cn}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setCn(e.target.value)}
      />

    </td>
    <td>
      <Form.Control
        type="text"
        value={sn}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSn(e.target.value)}
      />

    </td>
    <td>
      <Form.Control
        type="text"
        value={notes}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
      />
    </td>
    <td>
      <Button disabled>{TRASH_ICON}</Button>
    </td>
    <td>
      <Button
        variant="success"
        onClick={() => {
          const qp: AddSpecies = {
            common_name: cn,
            scientific_name: sn,
            notes
          };
          dispatch(editSpeciesThunk({
            qp,
            jwt,
            id: species.id
          }));
        }}>{SAVE_CHANGES_ICON}</Button>
    </td>

  </tr>;

  const nonEditingResponse = <><tr>
    <td>{species.id}</td>
    <td>{species.common_name}</td>
    <td>{species.scientific_name}</td>
    <td>{species.notes}</td>
    <td><Button onClick={() => setModalShow(true)}>{TRASH_ICON}</Button></td>
    <td><Button onClick={() => {
      dispatch(editButterfly(species.id));
    }}>
      {EDIT_ICON}
    </Button></td>
  </tr>
    <ConfirmModal
      show={modalShow}
      onConfirm={() => {
        setModalShow(false);
        dispatch(removeSpeciesThunk({
          jwt,
          id: species.id
        }));
      }}
      onCancel={() => setModalShow(false)}
      headText={`You are about to delete '${species.scientific_name || species.common_name || `id: ${species.id}`}'!`}
      bodyText={'Once you delete a species, you cannot undo this!'}
    />
  </>;

  return species.isEditing ? editingResponse : nonEditingResponse;
};

export default SpeciesRow;
