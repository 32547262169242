import { webGet, webPut, } from '../../webc/webClient';
import { SingleImageResponse, } from '../image/apiCalls';
import { BadRequestError, } from '../../webc/apiInterfaces';
interface About{
  body: string,
}
const ABOUT_ROUTE = 'about';
export const getAboutCall = async (): Promise<About> => {
  const response = await webGet(ABOUT_ROUTE);
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  }
  return json as About;
};

export const putAboutCall = async (jwt: string, body: { body: string }): Promise<About> => {
  let path = ABOUT_ROUTE;
  path += '?';
  path += new URLSearchParams({ jwt });
  const response = await webPut(path, body);
  const json = await response.json();
  if (response.status >= 400) {
    throw Error(json.toString());
  }
  return json as About;
};

export interface DailyImage {
  portrait: SingleImageResponse,
  landscape: SingleImageResponse,
}
const DAILY_IMAGE_ROUTE = 'daily-image';
export const getDailyImageCall = async (): Promise<DailyImage> => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const response = await webGet(DAILY_IMAGE_ROUTE, { timezone });
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  }
  return json as DailyImage;
};

interface Video {
  body: string,
}
const VIDEO_ROUTE = 'video';
export const getVideoCall = async (): Promise<Video> => {
  const response = await webGet(VIDEO_ROUTE);
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  }
  return json as Video;
};

export const putVideoCall = async (jwt: string, body: string): Promise<Video> => {
  let path = VIDEO_ROUTE;
  path += '?';
  path += new URLSearchParams({ jwt });
  const response = await webPut(path, { body });
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  }
  return json as Video;
};
