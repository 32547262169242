import { FULL_SERVER_ADDR, } from '../env';

type WebMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
export const API_BASE_PATH = `${FULL_SERVER_ADDR}/api/v1`;

export const webGet = async (path: string, data?: any): Promise<Response> => {
  if (data) {
    const queryParams = new URLSearchParams(data);
    path += `?${queryParams}`;
  }
  return webCall('GET', path);
};

export const webPost = async (path: string, data?: any): Promise<Response> => {
  return webCall('POST', path, data);
};

export const webPatch = async (path: string, data?: any): Promise<Response> => {
  return webCall('PATCH', path, data);
};

export const webDelete = async (path: string, data?: any): Promise<Response> => {
  if (data) {
    const queryParams = new URLSearchParams(data);
    path += `?${queryParams}`;
  }
  return webCall('DELETE', path, data);
};

export const webPut = async (path: string, data?: any): Promise<Response> => {
  return webCall('PUT', path, data);
};

const webCall = async (httpMethod: WebMethod, path: string, data?: any): Promise<Response> => {
  const url = `${API_BASE_PATH}/${path}`;

  return await fetch(url, {
    method: httpMethod,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      // 'X-Auth-Token': '123'
    },
    body: JSON.stringify(data)
  });
};
