import {
  webDelete,
  webGet,
  webPost,
  webPut,
} from '../../webc/webClient';
import { BadRequestError, } from '../../webc/apiInterfaces';

export interface Exhibitor {
  id: number,
  name: string,
  coords: string,
  city: string,
  state: string,
  country: string,
  url: string,
  notes: string,
}

export interface GetDelExhibitor {
  id?: number,
  name?: string,
}

export interface AddExhibitor {
  name: string,
  coords: string,
  url: string,
  notes: string,
}
export interface EditExhibitor {
  name?: string,
  coords?: string,
  url?: string,
  notes?: string,
}

export type AddExhibitorResponse = Exhibitor

const EXHIBITOR_PATH = 'exhibitor';

export const getExhibitorCall = async (qp: GetDelExhibitor, jwt: string): Promise<AddExhibitorResponse[]> => {
  const response = await webGet(EXHIBITOR_PATH, {
    ...qp,
    jwt
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw new BadRequestError(response.status, json.detail);
  }
  return json as AddExhibitorResponse[];
};

export const addExhibitorCall = async (qp: AddExhibitor, jwt: string): Promise<AddExhibitorResponse> => {
  const path = `${EXHIBITOR_PATH}?jwt=${jwt}`;
  const response = await webPost(path, qp);
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as AddExhibitorResponse;
};

export const removeExhibitorCall = async (qp: GetDelExhibitor, jwt: string): Promise<AddExhibitorResponse[]> => {
  const response = await webDelete(EXHIBITOR_PATH, {
    ...qp,
    jwt
  });
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as AddExhibitorResponse[];
};

export const putExhibitorCall = async (qp: EditExhibitor, jwt: string, id: number): Promise<AddExhibitorResponse> => {
  const path = `${EXHIBITOR_PATH}?jwt=${jwt}&id=${id}`;
  const response = await webPut(path, qp);
  const json = await response.json();
  if (response.status >= 400) {
    throw new Error(json.detail);
  }
  return json as AddExhibitorResponse;
};
