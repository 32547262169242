import React from 'react';
import { Button, Modal, } from 'react-bootstrap';

const ConfirmModal = ({
  show,
  onConfirm,
  onCancel,
  headText,
  bodyText,
}: { show: boolean, onConfirm: () => void, onCancel: () => void, headText: string, bodyText: string }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {headText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Are You Sure?</h4>
        <p>
          {bodyText}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button variant="danger" onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
