import { useAppDispatch, } from '../../app/hooks';
import { useSelector, } from 'react-redux';
import { selectJwt, } from '../auth/authSlice';
import React, { ChangeEvent, useState, } from 'react';
import {
  Button,
  Form,
  Table,
} from 'react-bootstrap';
import { addExhibitorThunk, exhibitorsListSelector, } from './exhibitorSlice';
import { AddExhibitor, } from './apiCalls';
import ExhibitorRow from './ExhibitorRow';
import { ADD_ICON, } from '../../common/uiText';

const AddExhibitorRow = () => {
  const dispatch = useAppDispatch();
  const jwt = useSelector(selectJwt);

  const [newExhibitorName, setNewExhibitorName] = useState('');
  const [newCoords, setNewCoords] = useState('');
  const [newNotes, setNewNotes] = useState('');
  const [newUrl, setNewUrl] = useState('');

  const submit = () => {
    const strippedCoords = newCoords.replace(/\s/g, '');

    const qp: AddExhibitor = {
      name: newExhibitorName,
      notes: newNotes,
      coords: strippedCoords,
      url: newUrl,
    };
    dispatch(addExhibitorThunk({
      qp,
      jwt
    }));
    setNewExhibitorName('');
    setNewCoords('');
    setNewNotes('');
    setNewUrl('');
  };

  return <tr>
    <td></td>
    <td>
      <Form.Control
        type="text"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
        value={newExhibitorName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNewExhibitorName(e.target.value)}
      />

    </td>
    <td>
      <Form.Control
        type="text"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
        value={newCoords}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNewCoords(e.target.value)}
      />
    </td>
    <td>{/* this is location, which is not set directly */}(this is derived from coordinates)</td>
    <td>
      <Form.Control
        type="text"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
        value={newUrl}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNewUrl(e.target.value)}
      />
    </td>
    <td>
      <Form.Control
        type="text"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
        value={newNotes}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setNewNotes(e.target.value)}
      />
    </td>
    <td>
      <Button
        onClick={submit}
      >{ADD_ICON}</Button>
    </td>
    <td>{/* Edit button column */}</td>
  </tr>;
};

const ExhibitorsTable = () => {
  const exhibitors = useSelector(exhibitorsListSelector);

  return <Table striped hover>
    <thead>
    <tr>
      <th>Exhibitor ID</th>
      <th>Exhibitor Name</th>
      <th>Coords</th>
      <th>Location</th>
      <th>Url</th>
      <th>Notes</th>
      <th>{/* Add/Remove button column */}</th>
      <th>{/* Edit button column */}</th>
    </tr>
    </thead>
    <tbody>
    {exhibitors.map(e => <ExhibitorRow exhibitor={e} key={e.id} />)}
    <AddExhibitorRow />
    </tbody>
  </Table>;
};

export default ExhibitorsTable;
