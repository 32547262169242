import React from 'react';
import { Button, } from 'react-bootstrap';

const OrientationSelector = ({ isPortrait, onChange } : {isPortrait: boolean | undefined, onChange: () => void}) => {
  return <>
    {isPortrait ? <Button onClick={onChange}>Portrait</Button> : <Button onClick={onChange}>Landscape</Button>}
  </>;
};

export default OrientationSelector;
