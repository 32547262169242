import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  deleteDateCall,
  getDateCall,
  GetDateCallParams,
  putDateCall,
} from './apiCalls';
import { RootState, } from '../../app/store';
import { SingleImageResponse, } from '../image/apiCalls';

export interface DateItem {
  date: string,
  portrait_img: SingleImageResponse,
  landscape_img: SingleImageResponse,
}
export enum DateStatus {
  Pending,
  Fulfilled,
  Rejected,
  Uninitialized,
}
interface DateSlice {
  dates: DateItem[];
  status: DateStatus,
  currentPortraitSelection: number,
  currentLandscapeSelection: number,
}

const dateItemSorter = (a: DateItem, b: DateItem) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);

  return aDate.getTime() - bDate.getTime();
};

export const getDateThunk = createAsyncThunk(
  'date/getDate',
  async (qp: GetDateCallParams, { rejectWithValue }) => {
    try {
      return await getDateCall(qp);
    } catch (e) {
      return rejectWithValue(qp);
    }
  }
);

export const putDateThunk = createAsyncThunk(
  'date/putDate',
  async ({
    jwt,
    date,
    portrait_img,
    landscape_img,
  }: { jwt: string, date: string, portrait_img: number, landscape_img: number, }, { rejectWithValue }) => {
    try {
      return await putDateCall(jwt, {
        date,
        portrait_img,
        landscape_img
      });
    } catch (e) {
      return rejectWithValue({
        date,
        portrait_img,
        landscape_img
      });
    }
  }
);

export const deleteDateThunk = createAsyncThunk(
  'date/deleteDate',
  async ({
    jwt,
    date
  }: { jwt: string, date: string }, { rejectWithValue }) => {
    try {
      return await deleteDateCall(jwt, date);
    } catch (e) {
      return rejectWithValue({ date });
    }
  }
);

const initialState: DateSlice = {
  currentLandscapeSelection: -1,
  currentPortraitSelection: -1,
  dates: [],
  status: DateStatus.Uninitialized
};
const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setCurrentLandscapeImageId: (state, action: PayloadAction<number>) => {
      state.currentLandscapeSelection = action.payload;
    },
    setCurrentPortraitImageId: (state, action: PayloadAction<number>) => {
      state.currentPortraitSelection = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDateThunk.fulfilled, (state, action) => {
        state.dates = action.payload.sort(dateItemSorter);
        state.status = DateStatus.Fulfilled;
      })
      .addCase(getDateThunk.pending, (state, action) => {
        state.status = DateStatus.Pending;
      })
      .addCase(getDateThunk.rejected, (state, action) => {
        state.status = DateStatus.Rejected;
      })
      .addCase(putDateThunk.fulfilled, (state, action) => {
        const dateItemIndex = state.dates.findIndex(item => action.payload.date === item.date);
        if (dateItemIndex !== -1) {
          state.dates[dateItemIndex] = action.payload;
        } else {
          state.dates.push(action.payload);
          state.dates.sort(dateItemSorter);
        }
      })
      .addCase(deleteDateThunk.fulfilled, (state, action) => {
        const dateItemIndex = state.dates.findIndex(item => action.payload.date === item.date);
        if (dateItemIndex !== -1) {
          state.dates.splice(dateItemIndex, 1);
        }
      })
    ;
  }
});

export const { setCurrentPortraitImageId, setCurrentLandscapeImageId } = dateSlice.actions;

export const selectDatesList = (state: RootState) => state.date.dates;
export const selectDatesStatus = (state: RootState) => state.date.status;
export const selectLandscapeDateImageId = (state: RootState) => state.date.currentLandscapeSelection;
export const selectPortraitDateImageId = (state: RootState) => state.date.currentPortraitSelection;
export default dateSlice.reducer;
