import React from 'react';
import { Button, Form, } from 'react-bootstrap';
import { SubmitHandler, useForm, } from 'react-hook-form';
import { useAppDispatch, } from '../../app/hooks';
import { Inputs, loginThunk, } from './authSlice';

const UserName = 'Email';
const UserPassword = 'Password';
const Submit = 'Submit';

export enum LoginStatus {
  uninitialized,
  success,
  failure,
}
const Login = () => {
  const {
    register,
    handleSubmit,
  } = useForm<Inputs>();
  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<Inputs> = (inputs) => {
    dispatch(loginThunk(inputs));
  };

  return <>
    <h2 className={'p-2'}>Login</h2>
  <Form
    onSubmit={handleSubmit(onSubmit)}
    className="p-3"
    >
    <Form.Group className="mb-3" controlId={UserName}>
      <Form.Label>{UserName}</Form.Label>
      <Form.Control
        type="text"
        placeholder={`Enter ${UserName}`}
        {...register('email', { required: true })}
      />
    </Form.Group>
    <Form.Group className="mb-3" controlId={UserPassword}>
      <Form.Label>{UserPassword}</Form.Label>
      <Form.Control
        type="password"
        placeholder={`Enter ${UserPassword}`}
        {...register('password', { required: true })} />
    </Form.Group>

    <Button variant="primary" type="submit" id="login-submit">
      {Submit}
    </Button>

  </Form>
  </>;
};

export default Login;
