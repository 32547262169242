import { Form, } from 'react-bootstrap';
import React, { ChangeEventHandler, } from 'react';
import { Exhibitor, } from '../features/exhibitor/apiCalls';

const ExhibitorSelector = ({
  onChange,
  exhibitors,
  defaultExhibitorId,
}: { onChange: ChangeEventHandler<HTMLSelectElement>, exhibitors: Exhibitor[], defaultExhibitorId?: number }) => {
  return <Form.Select
    aria-label={'Exhibitor'}
    onChange={onChange}
    defaultValue={defaultExhibitorId}
  >
    <option key={'none'}>None (assign later)</option>
    {
      exhibitors.map(e => {
        return (
          <option
            value={e.id}
            key={e.id}>
            {e.name}
          </option>
        );
      })
    }
  </Form.Select>;
};

export default ExhibitorSelector;
