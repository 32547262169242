import {
  Floppy,
  PencilSquare,
  PlusCircle,
  Trash,
} from 'react-bootstrap-icons';
import React from 'react';
import { Spinner, } from 'react-bootstrap';

export const TRASH_ICON = <Trash />;
export const ADD_ICON = <PlusCircle />;
export const EDIT_ICON = <PencilSquare />;
export const SAVE_CHANGES_ICON = <Floppy />;
export const PENDING_SPINNER = <Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner>;
export const REJECTED_MSG = 'Unable to access this item. Do you have permission?';
