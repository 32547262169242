import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import {
  AuthorizeCallData,
  loginCall,
  signupCall,
} from '../../webc/auth';
import { RootState, } from '../../app/store';
import { BadRequestError, } from '../../webc/apiInterfaces';

export interface AuthSlice {
  jwt: string,
  signupModalBody: string,
}

const initialState: AuthSlice = {
  jwt: '',
  signupModalBody: 'Nothing to see here!',
};
export type Inputs = {
  email: string,
  password: string,
}

export interface LoginDetailsError extends AuthorizeCallData {
  detail: string,
}

export const loginThunk = createAsyncThunk(
  'auth/fetchLogin',
  async (inputs: Inputs, { rejectWithValue }) => {
    try {
      return await loginCall(inputs);
    } catch (e) {
      const err = e as BadRequestError;
      const val: LoginDetailsError = {
        detail: err.detail,
        ...inputs
      };
      return rejectWithValue(val);
    }
  }
);
export const signupThunk = createAsyncThunk(
  'auth/fetchSignup',
  async (inputs: Inputs, { rejectWithValue }) => {
    try {
      return await signupCall(inputs);
    } catch (e) {
      const err = e as BadRequestError;
      const val: LoginDetailsError = {
        detail: err.detail,
        ...inputs
      };
      return rejectWithValue(val);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      state.jwt = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.jwt = action.payload?.access_token ?? '';
      })
      .addCase(signupThunk.fulfilled, (state, action) => {
        state.signupModalBody = `You have signed up at ${action.payload.email}. You must verify your email before continuing. Your user Id is ${action.payload.uid}`;
      })
      .addCase(signupThunk.rejected, (state) => {
        state.signupModalBody = 'There was an error signing up!';
      })
      .addCase(signupThunk.pending, (state) => {
        state.signupModalBody = 'Signing up...';
      })
    ;
  }
});
export const { signOut } = authSlice.actions;
export const selectJwt = (state: RootState) => state.auth.jwt;
export const selectModalText = (state: RootState) => state.auth.signupModalBody;
export default authSlice.reducer;
