import React from 'react';
import { useSelector, } from 'react-redux';
import { SpeciesStatus, speciesStatusSelector, } from './speciesSlice';
import { Container, Row, } from 'react-bootstrap';
import { PENDING_SPINNER, REJECTED_MSG, } from '../../common/uiText';
import SpeciesTable from './SpeciesTable';

const Species = () => {
  const status = useSelector(speciesStatusSelector);
  switch (status) {
    case SpeciesStatus.Uninitialized:
    case SpeciesStatus.Pending:
      return <Container><Row className="justify-content-md-center">{PENDING_SPINNER}</Row></Container>;
    case SpeciesStatus.Fulfilled:
      return <SpeciesTable />;
    case SpeciesStatus.Rejected:
      return <h4 className={'p-3'}>{REJECTED_MSG}</h4>;
  }
};

export default Species;
