import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useState,
} from 'react';
import {
  Button,
  Form,
  Table,
} from 'react-bootstrap';
import { useAppDispatch, } from '../../app/hooks';
import { useSelector, } from 'react-redux';
import { addSpeciesThunk, speciesListSelector, } from './speciesSlice';
import { selectJwt, } from '../auth/authSlice';
import { AddSpecies, } from './apiCalls';
import SpeciesRow from './SpeciesRow';
import { ADD_ICON, } from '../../common/uiText';

const SpeciesTable = () => {
  const dispatch = useAppDispatch();
  const species = useSelector(speciesListSelector);
  const jwt = useSelector(selectJwt);
  const [newSpeciesCommonName, setNewSpeciesCommonName] = useState('');
  const [newSpeciesScientificName, setNewSpeciesScientificName] = useState('');
  const [newNotes, setNewNotes] = useState('');
  const addSpecies = () => {
    const qp: AddSpecies = {
      common_name: newSpeciesCommonName,
      scientific_name: newSpeciesScientificName,
      notes: newNotes,
    };
    dispatch(addSpeciesThunk({
      jwt,
      qp
    }));
    setNewSpeciesCommonName('');
    setNewSpeciesScientificName('');
    setNewNotes('');
  };
  const submitOnEnterPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      addSpecies();
    }
  };

  return <Table striped hover>
    <thead>
    <tr>
      <th>Species ID</th>
      <th>Species Common Name</th>
      <th>Species Scientific Name</th>
      <th>Species Notes</th>
      <th></th>
      <th>{/* edit button col */}</th>
    </tr>
    </thead>
    <tbody>
    {species.map(s => <SpeciesRow species={s} jwt={jwt} key={s.id} />)}
    <tr key="add">
      <td></td>
      <td>
        <Form.Control
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setNewSpeciesCommonName(e.target.value)}
          value={newSpeciesCommonName}
          onKeyPress={submitOnEnterPress}
        />

      </td>
      <td>
        <Form.Control
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setNewSpeciesScientificName(e.target.value)}
          value={newSpeciesScientificName}
          onKeyPress={submitOnEnterPress}
        />

      </td>
      <td>
        <Form.Control
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setNewNotes(e.target.value)}
          value={newNotes}
          onKeyPress={submitOnEnterPress}
        />
      </td>
      <td>
        <Button onClick={addSpecies}>{ADD_ICON}</Button>
      </td>
      <td>{/* edit button col */}</td>
    </tr>
    </tbody>
  </Table>;
};

export default SpeciesTable;
