import React from 'react';
import DateItemSelector from './DateItemSelector';
import DateTable from './DateTable';
import { useSelector, } from 'react-redux';
import { DateStatus, selectDatesStatus, } from './dateSlice';
import { Container, Row, } from 'react-bootstrap';
import { PENDING_SPINNER, REJECTED_MSG, } from '../../common/uiText';

const DateTab = () => {
  const status = useSelector(selectDatesStatus);
  let bottomItem;
  switch (status) {
    case DateStatus.Uninitialized:
    case DateStatus.Pending:
      bottomItem = <Container><Row className="justify-content-md-center">{PENDING_SPINNER}</Row></Container>;
      break;
    case DateStatus.Fulfilled:
      bottomItem = <>
        <DateItemSelector />
        <hr />
        <DateTable />
      </>;
      break;
    case DateStatus.Rejected:
      bottomItem = <h4 className={'p-3'}>{REJECTED_MSG}</h4>;
      break;
  }

  return <>
    {bottomItem}
  </>;
};

export default DateTab;
