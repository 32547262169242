import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { RootState, } from '../../app/store';
import {
  getAboutCall,
  getDailyImageCall,
  getVideoCall,
  putAboutCall,
  putVideoCall,
} from './apiCalls';
import { BadRequestError, } from '../../webc/apiInterfaces';
import { SingleImageResponse, } from '../image/apiCalls';

interface HomeSlice {
  about: string,
  video: string,
  landscape?: SingleImageResponse,
  portrait?: SingleImageResponse,
}

const initialState: HomeSlice = {
  about: '',
  video: '',
};
export const getAboutThunk = createAsyncThunk(
  'home/getAbout',
  async () => {
    try {
      return await getAboutCall();
    } catch (e) {
      console.error('no connection....???!!?!?!?!');
    }
  }
);

export const putAboutThunk = createAsyncThunk(
  'home/editAbout',
  async ({
    jwt,
    body
  }: { jwt: string, body: string }, { rejectWithValue }) => {
    try {
      return await putAboutCall(jwt, { body });
    } catch (e) {
      rejectWithValue({
        jwt,
        body
      });
    }
  }
);

export const getDailyImageThunk = createAsyncThunk(
  'home/dailyImage',
  async () => {
    try {
      return await getDailyImageCall();
    } catch (e) {
      const err = e as BadRequestError;
      console.error(err);
    }
  }
);

export const getVideoThunk = createAsyncThunk(
  'home/getVideo',
  async () => {
    try {
      return await getVideoCall();
    } catch (e) {
      const err = e as BadRequestError;
      console.error(err);
    }
  }
);
export const putVideoThunk = createAsyncThunk(
  'home/putVideo',
  async ({
    jwt,
    body
  }: { jwt: string, body: string }, { rejectWithValue }) => {
    try {
      return await putVideoCall(jwt, body);
    } catch (e) {
      rejectWithValue({
        jwt,
        body
      });
    }
  }
);

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAboutThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.about = action.payload?.body || '';
        }
      })
      .addCase(putAboutThunk.fulfilled, (state, action) => {
        state.about = action.payload?.body || state.about;
      })
      .addCase(getDailyImageThunk.fulfilled, (state, action) => {
        state.landscape = action.payload?.landscape || undefined;
        state.portrait = action.payload?.portrait || undefined;
      })
      .addCase(getVideoThunk.fulfilled, (state, action) => {
        state.video = action.payload?.body || '';
      })
      .addCase(putVideoThunk.fulfilled, (state, action) => {
        state.video = action.payload?.body || state.video;
      })
      .addCase(getAboutThunk.rejected, (state, action) => {
        state.video = '';
      })
    // .addCase(putAboutThunk.rejected, (state, action) => {
    //
    // })
    ;
  }
});

export default homeSlice.reducer;
export const selectAbout = (state: RootState) => state.home.about;
export const selectDailyPortrait = (state: RootState) => state.home.portrait;
export const selectDailyLandscape = (state: RootState) => state.home.landscape;
export const selectVideo = (state: RootState): string => state.home.video;
